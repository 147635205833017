<template>
  <div class="category-button"
       :class="{'category-button--active': isSelected,
                'category-button--edit': isEdit}"
       @click="clickButton">
    <div v-show="!isEdit">
      <span>{{ categoryCopy.name }}</span>

      <span class="category-button__image-wrapper" @click.stop="clickEdit">
        <img src="../../../../../assets/img/button/edit.svg" alt="edit">
      </span>

      <app-popover @click-yes="$emit('delete-category', category.id)">
        <p slot="title">Удалить направление?</p>

        <div slot="icon">
          <span class="category-button__image-wrapper">
            <img src="@/assets/img/button/delete.svg" alt="delete">
          </span>
        </div>
      </app-popover>

    </div>
    <div v-show="isEdit">
      <el-input placeholder="Введите название направления" v-model="categoryCopy.name"
                @keypress.enter.native.prevent="clickEditSave"/>
      <img class="category-button__image" src="../../../../../assets/img/button/edit.svg" alt="edit"
           @click.stop="clickEditSave">
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import imgToSvg from "@/assets/js/imgToSvg"
import AppPopover from "../../../../../components/AppPopover";

export default {
  name: "CategoryItem",
  components : {AppPopover},
  props: {
    category: Object,
  },
  computed: {
    ...mapGetters(['selectedCategory']),

    isSelected() {
      return this.selectedCategory && this.selectedCategory.id === this.category.id
    }
  },
  data() {
    return {
      categoryCopy: Object,
      isEdit: false
    }
  },
  mounted() {
    imgToSvg('.category-button__image-wrapper img')
    this.categoryCopy = {...this.category}
  },
  methods: {
    clickButton: function () {
      if (!this.isEdit) this.$emit('click', this.categoryCopy)
    },
    clickEdit: function () {
      this.isEdit = !this.isEdit
    },
    clickEditSave: function () {
      this.isEdit = !this.isEdit
      this.$emit('update-category', this.categoryCopy)
    },
  }
}
</script>

<style lang="scss">
.category-button {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  padding-left: 32px;
  padding-right: 32px;

  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  color: #121212;
  background: #FFFFFF;


  height: 54px;

  border: 2px solid #1454F2;
  box-sizing: border-box;
  border-radius: 120px;

  cursor: pointer;

  transition: all .1s ease-in;

  text-decoration: none;

  span:first-child {
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__image-wrapper {
    display: inline-flex;
    height: max-content;
    width: 16px;
    margin-left: 15px;
  }

  &__image-wrapper:last-child {
    margin-left: 20px;
  }

  div {
    display: flex;
    align-items: center;

    span:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--active {
    background: #1454F2;
    color: #FFFFFF;

    svg path {
      fill: #FFFFFF;
      transition: all .1s ease-in;
    }
  }

  &--edit {
    background: #FFFFFF;
  }

}
</style>

<style lang="scss">
.category-button {
  &--active {
    svg path {
      fill: #FFFFFF;
      transition: all .1s ease-in;
    }
  }

  .el-input__inner {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    padding-left: 0px;
    border: none;
    border-radius: 50px;
  }
}
</style>