<template>
  <base-modal
      class="add-direction-wrapper"
      :visible="isVisible"
      title="Добавить направление"
      :loading="loading"
      @submit="handleSave"
      @close="closeModal">
    <template>
      <form-input label="Название"
                  class="add-direction-wrapper__direction"
                  v-model.trim="categoryName"
                  :validator="$v.categoryName"
                  maxLength="51"
                  placeholder="Направление"/>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../../../../../../components/BaseModal";
import FormInput from "@/components/FormInput";

import {validationMixin} from "vuelidate";
import {required, minLength, maxLength} from 'vuelidate/lib/validators'
import {unique, validate} from "../../../../../../utils/validators";

export default {
  name: "AddCategoryModal",
  mixins: [validationMixin],
  components: {
    BaseModal,
    FormInput
  },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      isVisible: false,
      categoryName: '',
      categories: null
    }
  },
  methods: {
    openModal: function (categories) {
      this.categoryName = ''
      this.isVisible = true
      this.categories = categories
    },
    closeModal: function () {
      this.categoryName = ''
      this.categories = []
      this.isVisible = false

      this.$v.$reset()
    },
    handleSave: function () {
      if (validate(this.$v)) {
        this.$emit('add-category', this.categoryName)
      }
    },
  },
  validations: {
    categoryName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(60),
      unique: unique('categories', 'name', 'Направление с таким именем уже существует')
    },
  }
}
</script>

<style lang="scss">
.add-direction-wrapper {
  .el-dialog {
    border-radius: 4px;
  }

  &__direction {
    .form-input__input {
      border-radius: 50px;
      border: 2px solid #1454F2;
      font-family: 'Montserrat';
      font-size: 14px;

      &:focus {
        border-color: #1454F2;
      }
    }
  }

  .el-select {
    width: 100%;
    margin-top: 7px;
    border-color: #1454F2;

    &__input {
      margin-left: 8px;
    }

    &__tags {
      padding: 5px 10px;

      .el-tag.el-tag--info {
        background-color: #1454F2;
        border-color: #1454F2;
        color: #FFFFFF;
        border-radius: 50px;
        padding-left: 15px;
        padding-right: 30px;

        position: relative;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .el-tag.el-tag--info .el-tag__close {
        border: 1px #FFFFFF solid;
        color: #FFFFFF;
        background: #1454F2;

        position: absolute;
        right: 5px;
        top: 4px;
      }
    }

    .el-input__inner {
      border-radius: 50px;
      border: 2px solid #1454F2;

      &::placeholder {
        font-family: 'Raleway', sans-serif;
        font-size: 12px;
        color: #9D9D9D;
      }
    }

    .el-input.is-focus .el-input__inner {
      border-color: #1454F2;
    }
  }

  &__select {
    .el-select-dropdown__item {
      font-family: Roboto, sans-serif;
      color: #121212;
      font-weight: 400;
    }

    .el-scrollbar__wrap {
      max-height: 300px;
    }

    .el-select-dropdown__empty {
      padding: 10px 0;
      margin: 0;
      text-align: center;
      color: #999;
      font-family: Raleway;
      font-size: 14px;
    }
  }

  &__select-header {
    display: flex;
    justify-content: space-between;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: #404040;
  }

  &__select-title {
    &--error {
      color: red;
    }
  }

  &__select-title-error {
    font-size: 10px;

    max-width: 250px;
    color: red;
  }


  .base-modal__header {
    &--title {
      margin-left: 0px !important;
    }
  }
}

</style>