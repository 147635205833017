import http from '@/app/admin/config/admin-axios.config'

class AuthRepository {
    
    async auth(requestData) {
        const response = await http.post(`/login`, requestData)
        return Promise.resolve(response.data)
    }

}

export const authRepository = new AuthRepository()
