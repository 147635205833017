<template>
  <div class="course-program-info">
    <course-header-program/>

    <div v-for="item in course.themes" :key="item.id">
      <div class="course-program-info__theme-title">
        {{ item.name }}
      </div>
      <lesson-info v-for="(lesson,index) in item.lessons" :key="index"
                   :lessonInfo="lesson"
                   :show-lesson="lesson.id === selectedNumberLesson"
                   @selectedLesson="selectedNumberLesson = $event"
                   :number-lesson="index+1"/>
    </div>

  </div>
</template>

<script>
import CourseHeaderProgram from "../CourseHeaderProgram";
import LessonInfo from "./components/LessonInfo";
import imgToSvg from "../../../../../assets/js/imgToSvg";

export default {
  name: "CourseProgramInfo",
  props: {
    course: [Array, Object],
    // testsCount: {type: Number, default: 0},
    purchased: {type: Boolean, default: false},
    available: {type: Boolean, default: null},
  },
  components: {
    CourseHeaderProgram,
    LessonInfo,
  },
  data() {
    return {
      selectedNumberLesson: null
    }
  },
  mounted() {
    imgToSvg('.course-program-info__arrow img')
  },
}
</script>

<style lang="scss">
.course-program-info {
  display: flex;
  flex-direction: column;

  padding: 65px;
  border: 2px solid #DCDFE6;
  border-radius: 26px 100px 26px 26px;

  margin: 0 auto 140px auto;

  transition: 0.2s;

  &__theme-title {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 600;
  }

  @media screen and (max-width: 1200px) {
    margin: 0 auto 120px auto;
  }
  @media screen and (max-width: 980px) {
    margin: 0 auto 100px auto;
  }
  @media screen and (max-width: 740px) {
    margin: 0 auto 80px auto;
  }

  @media screen and (max-width: 700px){
    padding: 45px;
  }
  @media screen and (max-width: 600px) {
    margin: 0 auto 60px auto;
    padding: 0;
    border: none;
  }

}

</style>
