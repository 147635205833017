<template>
  <div class="course-header-info">
    <div class="course-header-info__info"
         :class="{'course-header-info__info--w100': !videoIntro.path}">

      <div class="course-header-info__category">
        <div>курс</div>
        <div>{{ categoryName }}</div>
      </div>

      <div class="course-header-info__title">{{ name }}</div>

<!--      <div class="course-header-info__buy-course">-->
<!--        <div @click="clickButtonBuy" class="course-header-info__buy-btn">-->
<!--          Записаться на курс-->
<!--        </div>-->
<!--&lt;!&ndash;        <div class="course-header-info__price">&ndash;&gt;-->
<!--&lt;!&ndash;          {{ price }} ₽&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->

<!--      </div>-->

      <div class="course-header-info__lesson-count">
        <div>
          {{ lessonsCount || 0 }}
          <span>Уроков</span>
        </div>
      </div>

    </div>

    <div class="course-header-info__video-preview"
         v-if="videoIntro.path && videoIntro.preview">
      <div class="course-header-info__preview-img" @click="showModal = true">
        <img :src="previewImage" alt="">
      </div>
      <div class="course-header-info__author">
        <div class="course-header-info__author-name">
          {{ author.name }}
          <img src="@/assets/img/user/author-check.svg" alt="check">
        </div>
        <div class="course-header-info__author-position">
          {{ author.position }}, автор курса
        </div>
      </div>
    </div>

    <transition name="el-zoom-in-center" :duration="22000" mode="out-in">
      <course-video-intro-modal v-if="showModal"
                                :path="videoIntro.path"
                                @closeModal="showModal = false"/>
    </transition>
  </div>
</template>

<script>

import CourseVideoIntroModal from "../../../../../user/courseInfo/components/CourseVideoIntroModal";

export default {
  name: "CourseHeaderInfo",
  components: {
    CourseVideoIntroModal
  },
  props: {
    name: {type: String},
    price: {type: [Number, String]},
    lessonsCount: {type: [Number, String]},
    author: {type: Object},
    videoIntro: {type: Object},
    available: {type: Boolean, default: false},
  },
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth
    },
    discountPrice() {
      const discount = (+this.course.price - +this.course.oldPrice) / +this.course.oldPrice * 100
      return discount < 0 ? discount * -1 : discount
    },
    categoryName() {
      return this.$store.state.course.selectedCategory?.name || 'Без направления'
    },
    previewImage() {
      return this.videoIntro.preview instanceof Blob
          ? URL.createObjectURL(this.videoIntro.preview)
          : this.videoIntro.preview;
    }
  },
  methods: {
    clickButtonBuy() {
      if(this.$route.name === 'admin-courses')
        return
      this.$router.push({query: {enroll: null}})
      this.available ? this.buyCourse() : this.NotAvailableCourse()
    },
    NotAvailableCourse() {
      this.$store.commit('myCourses.setShowNotAvailableCourse', true)
    },
    buyCourse() {
      this.$store.commit('myCourses.setShowBuyCoursePopup', true)
    },

  }
}
</script>

<style lang="scss" scoped>
.course-header-info {
  display: flex;

  margin-bottom: 140px;

  @media screen and (max-width: 1200px) {
    margin-bottom: 120px;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 100px;
  }
  @media screen and (max-width: 740px) {
    margin-bottom: 80px;
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 70px;
  }


  &__info {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-right: 30px;
    max-width: 680px;
    width: 100%;

    &--w100 {
      max-width: 100%;
    }
  }

  &__category {
    display: flex;
    margin-bottom: 16px;
    padding-top: 22px;

    div {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      text-transform: lowercase;
      color: #1454F2;
      background: rgba(20, 84, 242, 0.1);
      border-radius: 200px;

      padding: 6px 20px;
    }

    div:last-child {
      color: #FF9F8C;
      background: rgba(255, 159, 140, 0.1);
      margin-left: 8px;
    }
  }

  &__title {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 70px;
    text-transform: lowercase;
    color: #222222;

    width: 100%;
    margin-bottom: 52px;
  }

  &__buy-course {
    display: flex;
    align-items: center;

    margin-bottom: 82px;
  }

  &__buy-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 282px;
    height: 74px;

    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;

    margin-right: 28px;

    color: #FFFFFF;
    background: #1454F2;
    border-radius: 18px;

    cursor: pointer;

    transition: 0.2s;

    &:hover {
      background-color: #4072ef;
    }

    @media screen and (max-width: 980px) {
      margin-right: 16px;
      width: 260px;
      height: 68px;
    }
  }

  &__price {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    color: #222222;
    white-space: nowrap;

    @media screen and (max-width: 980px) {
      font-size: 36px;
    }
  }

  &__lesson-count {
    display: flex;

    width: max-content;
    background: #F7F7F7;
    border-radius: 26px;
    padding: 21px 40px;

    div {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      color: #222222;

      &:first-child {
        margin-right: 40px;
      }

      span {
        display: block;
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 110%;
        color: #222222;
        opacity: 0.5;
        margin-top: 8px;
      }
    }
  }

  &__video-preview {
    width: 490px;
    height: max-content;

    border: 4px solid #FFFFFF;
    box-shadow: 0 30px 150px -40px rgba(0, 0, 0, 0.2);
    border-radius: 26px;
  }

  &__preview-img {
    position: relative;
    pointer-events: none;
    width: 480px;
    height: 490px;
    > img {
      height: 100%;
      width: 100%;

      object-fit: cover;
      border-top-left-radius: 26px;
      border-top-right-radius: 26px;
    }

    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 120px;
      height: 120px;

      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      pointer-events: auto !important;

      margin: auto;
      cursor: pointer;

      background: rgba(255, 255, 255, 0.6);
      border-radius: 100%;
      backdrop-filter: blur(40px);

      background-image: url("../../../../../../assets/img/user/icon-play.svg");
      background-repeat: no-repeat;
      background-position: center;

    }
  }

  &__author {
    padding: 28px 40px;
    box-sizing: border-box;
    width: 480px;
  }

  &__author-name {
    display: flex;
    align-items: center;

    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 100%;
    color: #222222;

    margin-bottom: 12px;

    img {
      margin-left: 10px;
    }
  }

  &__author-position {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #222222;
    opacity: 0.5;
  }

}
</style>
