<template>
  <transition appear name="el-fade-in" mode="out-in">
    <div class="lesson-video-intro" v-if="isVisible" :key="isVisible">
      <div class="lesson-video-intro__title" v-if="!path">
        Посмотрите видео-презентацию курса и узнайте что вас ждет
      </div>
      <vue-vimeo-player v-if="vimeoId"
                        ref="vimeoPlayer"
                        class="lesson-video-intro__video"
                        :video-id="vimeoId"
                        :playsinline="true"
                        :autoplay="!!path"
                        @error="isVisible = false"
                        :options="vimeoOptions"/>
      <video-player v-else
                    class="vjs-custom-skin lesson-video-intro__video"
                    ref="videoPlayer"
                    :options="playerOptions"
                    :playsinline="true"/>

    </div>
  </transition>

</template>

<script>
import {coursesService} from "@/app/user/courses/courses.service";
import {courseService} from "../../../../../admin/course/course.service";
import {videoPlayer} from 'vue-video-player'
import {vueVimeoPlayer} from 'vue-vimeo-player'
import {vimeoLink} from "../../../../../../utils/validators";

export default {
  name: "LessonVideoIntro",
  components:{
    videoPlayer,
    vueVimeoPlayer
  },
  props:{
    courseId:[Number,String],
    path: {type: String, default: null}
  },
  data(){
    return {
      vimeoId: null,
      isVisible: false,
      playerOptions: {
        width: '700',
        height: '400',
        language: 'ru',
        autoplay: false,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: ""
        }],
        poster: "/static/images/author.jpg",
      },
      vimeoOptions: {
        width: 700,
        title: false,
        byline: false,
        height: 400,
      }
    }
  },
  mounted() {
    if(this.path !== null) {
      if (vimeoLink(this.path))
        this.vimeoId = this.path.match(/(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/|\/videos\/|video\/|)(\d+)(?:|\/\?)/)[1]
      else {
        this.playerOptions.sources[0].src = `${process.env.VUE_APP_BACKEND_HOST}/watch?path=${this.path}`
        this.playerOptions.autoplay = true
      }
      this.isVisible = true
    }
    else {
      if(this.$route.name === 'course-info') {
        coursesService.getLessonIntro(this.courseId).then((data) => {
          if (vimeoLink(data.path))
            this.vimeoId = data.path.match(/(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/|\/videos\/|video\/|)(\d+)(?:|\/\?)/)[1]
          else
            this.playerOptions.sources[0].src = `${process.env.VUE_APP_BACKEND_HOST}/watch?path=${data.path}`

          this.isVisible = data.isVisible
        })
      }
      else {
        courseService.getLessonIntro(this.courseId).then((data) => {
          if (vimeoLink(data.path))
            this.vimeoId = data.path.match(/(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/|\/videos\/|video\/|)(\d+)(?:|\/\?)/)[1]
          else
            this.playerOptions.sources[0].src = `${process.env.VUE_APP_BACKEND_HOST}/watch?path=${data.path}`

          this.isVisible = true
        })
      }
    }

  }
}
</script>

<style lang="scss">
.lesson-video-intro{

  .video-js {
    border-radius: 4px;


    @media screen and (max-width: 750px){
      height: 300px;
    }
    @media screen and (max-width: 550px){
      height: 265px;
    }
    @media screen and (max-width: 490px){
      height: 230px;
    }

    .vjs-poster,.vjs-control-bar, .vjs-modal-dialog {
      border-radius: 4px;
    }
  }

  &__title {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 8px;
  }
  &__video{
    max-width: 700px;
    max-height: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;

    iframe {
      border-radius: 4px;
      width: 100%;

      @media screen and (max-width: 750px){
        height: 300px;
      }
      @media screen and (max-width: 550px){
        height: 265px;
      }
      @media screen and (max-width: 490px){
        height: 230px;
      }
      @media screen and (max-width: 420px){
        height: 200px;
      }
      @media screen and (max-width: 360px){
        height: 180px;
      }
    }
  }
}
</style>