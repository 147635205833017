import http from "@/app/user/config/user-axios.config";

class LessonsRepository {
    async getThemes(courseId) {
        const response = await http.get(`/spa/courses/${courseId}/themes`)
        return response.data.data
    }
    async getInfoCourse(courseId) {
        const response = await http.get(`/common/courses/${courseId}`)
        return response.data
    }
    async downloadFile(lessonId,attachmentId) {
        const response = await http.get(`/spa/lessons/${lessonId}/attachment/${attachmentId}`,{responseType: 'blob'})
        return response.data
    }
    async passedLesson(lessonId) {
        const response = await http.post(`/spa/lessons/${lessonId}/complete`)
        return response.data
    }
    async passedTestsLesson(lessonId,selectedIds) {
        const response = await http.post(`/spa/lessons/${lessonId}/complete_task`, {selectedIds: selectedIds})
        return response.data
    }
}

export const lessonsRepository = new LessonsRepository()
