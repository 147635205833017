<template>
  <div class="admin-courses" v-if="coursesWithoutCategories.length">
    <div class="admin-courses__header ">
      <div class="admin-courses__title" :class="{'admin-courses__title--active': isShowCourses}" @click="changeShowCourseStatus">
        <span>Курсы без направлений</span>
        <i class="el-icon-arrow-down"></i>
      </div>
    </div>

    <el-collapse-transition>
      <transition-group name="list" class="admin-courses__buttons" v-show="isShowCourses">
        <course-item class="admin-courses__button"
                     v-for="item of coursesWithoutCategories"
                     :key="item.id"
                     @click="selectCourse(item.id)"
                     @delete-course="deleteCourse(item.id)"
                     :course="item"/>
      </transition-group>
    </el-collapse-transition>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

import CourseItem from "./CourseItem";
import {courseService} from "../../course.service";

export default {
  name: "course-without-categories",
  components: {
    CourseItem,
  },
  computed: {
    ...mapGetters(['courses']),

    coursesWithoutCategories() {
      return this.courses.filter(course => !course.categories || !course.categories.length)
    }
  },
  data() {
    return {
      isShowCourses: false
    }
  },
  methods: {
    ...mapMutations(['selectCourseId', 'deleteCourseById', 'selectCategory']),

    deleteCourse: function (courseId) {
      this.deleteCourseById(courseId)
      if (this.coursesWithoutCategories.length) this.selectCourseId(this.coursesWithoutCategories[0].id)
      courseService.deleteCourse(courseId)
    },

    changeShowCourseStatus: function () {
      this.isShowCourses = !this.isShowCourses
    },

    selectCourse: function (courseId) {
      this.selectCategory(null)
      this.selectCourseId(courseId)
    }
  }
}
</script>

<style scoped lang="scss">
.admin-courses {
  &__buttons {
    display: flex;
    flex-wrap: wrap;
  }

  &__button {
    margin-right: 15px;
    margin-top: 24px;
  }

  &__title {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #2A2A2A;
    cursor: pointer;

    .el-icon-arrow-down {
      margin-left: 15px;
      transition: .3s;
    }

    &--active {
      .el-icon-arrow-down {
        transform: rotate(180deg);
      }
    }
  }
}
</style>