<template>
  <div>
    <categories-and-courses></categories-and-courses>

    <transition appear name="el-fade-in" :duration="80" mode="out-in">

      <div v-if="courseInstance && loadingCourse">
        <course-control :course="courseInstance"
                        :is-editing="isEditing"
                        :loading="loading"
                        :key="isEditing"
                        @edit="editCourse"
                        @save="saveCourse">
        </course-control>
        <component :is="currentMode"
                   :course="courseInstance"
                   :key="courseInstance.id">
        </component>
      </div>
      <course-loading v-else/>


    </transition>

  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import ProgressNotification from "./progressNotification/progressNotification";
import {courseService} from "./course.service";
import {notificationsHelper} from "../../../helpers/notifications.helper";
import CategoriesAndCourses from "./components/categoriesAndCourses/CategoriesAndCourses";
import CourseEditor from "./components/courseEditor/CourseEditor";
import CourseInfo from "./components/courseInfo/CourseInfo";
import CourseControl from "./components/CourseControl";
import CourseLoading from "./components/CourseLoading/CourseLoading";

export default {
  name: "CoursePageWrapper",
  components: {
    CourseControl,
    CourseEditor,
    CourseInfo,
    CategoriesAndCourses,
    CourseLoading
  },
  computed: {
    ...mapGetters(['selectedCourseId', 'selectedCategory','courses']),

    currentMode() {
      return `course-${this.isEditing ? 'editor' : 'info'}`
    },
    host(){
      return process.env.VUE_APP_BACKEND_HOST
    },
  },
  beforeDestroy() {
    this.selectCategory(null)
    this.selectCourseId(null)
    ProgressNotification.close()
  },
  data() {
    return {
      courseInstance: null,
      isEditing: false,
      loading: false,
      loadingCourse: false,
    }
  },
  methods: {
    ...mapMutations(['setCourses', 'selectCategory', 'selectCourseId', 'deleteCourseById']),

    loadCourseById: async function () {
      this.loadingCourse = false
      this.courseInstance = await courseService.getCourseById(this.selectedCourseId).finally(()=>{
        setTimeout(()=>this.loadingCourse = true,300)

      })
    },
    saveCourse: async function () {
      if(!this.courseInstance.certificateTemplateId) {
        notificationsHelper.warning('Сертификат не выбран')
        return
      }
      this.loading = true

      try {
        await courseService.updateCourse(this.courseInstance)
            .then(data=>{
          if(data.reviews) {
            this.courseInstance.reviews = data.reviews.map(item => ({
              ...item,
              picture: `${this.host}/common/courses/${data.id}/review/${item.id}/picture?t=${Date.now().toString()}`
            })).sort((a,b)=>{
              return a.id - b.id
            })
          }
          else
            this.courseInstance.reviews = []
        })

        this.courseInstance.deletedReviews = []
        this.courseInstance.addedReviews = []
        this.courseInstance.deletedCertificates = []

        // const promises = []

        // promises.push(courseService.getAuthorCertificatesById(this.courseInstance.id))
        // promises.push(courseService.getLessons(this.selectedCourseId))
        // promises.push(courseService.getLessonIntro(this.selectedCourseId))

        // const responses = await Promise.all(promises)

        // this.courseInstance.author.certificatesPhoto = responses[0]
        // this.courseInstance.lessons = responses[1]
        // this.courseInstance.videoIntro = {
        //   path: responses[2].path,
        //   preview: responses[2].filePreview
        // }

        //обновляем название кнопочки курс
        this.courses.find(course=> course.id === this.courseInstance.id).name = this.courseInstance.name

        this.$notify.success({
          title: 'Успех',
          message: 'Курс успешно обновлен'
        })

        if (this.courseInstance.picture instanceof File) {
          this.courseInstance.picture = `${this.$store.state.host}/common/courses/${this.courseInstance.id}/picture?t=${Date.now().toString()}`
        } else {
          const url = new URL('', this.courseInstance.picture)
          url.searchParams.delete('t')
          url.searchParams.append('t', Date.now().toString())
          this.courseInstance.picture = url.toString()
        }

        this.courseInstance.reviews.forEach(review => {
          if (review.picture instanceof File) {
            review.picture = window.URL.createObjectURL(review.picture)
          }
        })

        if (this.courseInstance.author.picture instanceof File) {
          this.courseInstance.author.picture = window.URL.createObjectURL(this.courseInstance.author.picture)
        }

        await this.loadCourseById()

        this.isEditing = false
      } catch (error) {
        console.log(error)
        notificationsHelper.error('Не удалось сохранить курс')
      } finally {
        this.loading = false
      }
    },
    editCourse: async function () {
      this.isEditing = true
    }
  },
  watch: {
    selectedCourseId() {
      this.isEditing = false

      if (this.selectedCourseId) {
        this.loadCourseById()
      } else {
        this.courseInstance = null
      }
    },
    selectedCategory() {
      this.isEditing = false
    }
  },
}
</script>

<style scoped lang="scss">

</style>
