import {lessonsRepository} from "./lessonsCourse.repository";
import store from "@/store/index";

class LessonsService {

    async getInfoThemes(courseId) {
        const themes = await lessonsRepository.getThemes(courseId)
        store.commit('lessons.setThemesInfo', themes)
        return themes
    }

    async getInfoCourse(courseId) {
        try {
            const course = await lessonsRepository.getInfoCourse(courseId)
            return course
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async downloadFile(lessonId, attachmentId) {
        try {
            const response = await lessonsRepository.downloadFile(lessonId, attachmentId)
            return response
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async passedLesson(lessonId) {
        try {
            const response = await lessonsRepository.passedLesson(lessonId)
            store.commit('lessons.setPassingLesson', lessonId)

            return response
        } catch (error) {
            console.log(error)
            throw error
        }
    }
    async passedTestsLesson(lessonId,selectedIds) {
        try {
            const response = await lessonsRepository.passedTestsLesson(lessonId,selectedIds)
            return response.doneRight
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}

export const lessonsService = new LessonsService()
