<template>
  <div class="course-certificate">
    <div class="admin-course__title">cертификат об окончании курса</div>
      <div class="course-certificate__form-group">
        <label>Сертификат</label>
        <el-select v-model="temp"
                   popper-class="course-certificate-select"
                   placeholder="Select"
                   @change="changeCertificate">
          <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
  </div>
</template>

<script>
import {certificatesService} from "@/app/admin/certificates/certificates.service";

export default {
  name: "CourseCertificate",
  model: { prop: 'certificateTemplateId', event: 'input' },
  props: {
    certificateTemplateId: { type: Number, default: null }
  },
  data() {
    return {
      temp: null,
      options: []
    }
  },
  created() {
    certificatesService.getCertificates()
        .then(data => {
          this.options = data.map(item => ({
            id: item.id,
            name: item.name
          }))
        })
  },
  mounted() {
    if (this.certificateTemplateId) {
      this.temp = this.certificateTemplateId
    }
  },
  methods: {
    changeCertificate() {
      this.$emit('input', this.temp)
    }
  }
}
</script>

<style lang="scss">
.course-certificate {
  margin-bottom: 140px;

  .admin-course__title {
    text-align: center;
  }
  &__form-group {
    width: 500px;
    margin: 0 auto;

    label {
      display: block;

      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 8px;
      color: #121212;
    }

    .el-select {
      width: 500px;

      input {
        height: 54px;
        font-family: Gilroy, sans-serif;
        font-size: 18px;
      }

    }

  }

}
.course-certificate-select {
  .el-select-dropdown__item {
    font-size: 16px;
    font-family: Gilroy, sans-serif;
  }
}
</style>
