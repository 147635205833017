<template>
  <div class="form-input" :class="{'form-input--error': isError}">
    <span class="form-input__error-text"
          :style="{'top': label === '' ? '-15px' : ''}"
          v-if="isError">
      {{validationErrorMessage}}
    </span>

    <label class="form-input__label" :class="{'form-input__label--required': required}">
      <span v-if="!required && label">{{ label }}</span>
      <input class="form-input__input"
             :class="{'form-input__input--password': inputType === 'password' && value}"
             :type="inputType"
             :readonly="readonly"
             v-mask="mask"
             :placeholder="placeholder"
             :maxLength="maxLength"
             :value="value"
             @focus="$emit('focus')"
             @input="$emit('input', $event.target.value)">
      <img v-if="type === 'password'"
           class="form-input__eye"
           v-show="type === 'password' && value"
           @click="isShowPassword = !isShowPassword"
           :src="require(`@/assets/img/user/${!isShowPassword ? 'eye-visible.svg' : 'eye-invisible.svg'}`)"
           alt="eye">
    </label>

  </div>
</template>

<script>
import {validationMap} from "@/utils/validators";

export default {
  name: "FormInput",
  props: {
    value: String,
    label: {type: String, default: ''},
    placeholder: String,
    readonly: Boolean,
    validator: Object,
    type: { type: String, default: 'text' },
    clearable: { type: Boolean, default: false },
    mask: { type: String, default: null },
    required: { type: Boolean, default: false },
    maxLength: { type: String, default: null }
  },
  computed: {
    inputType() {
      if (this.type !== 'password') return this.type
      else {
        if (!this.isShowPassword) return 'password'
        else return 'text'
      }
    },

    isError() {
      return this.validator && this.validator.$error
    },

    validationErrorMessage() {
      for (const validationItem in this.validator) {
        if (Object.prototype.hasOwnProperty.call(this.validator, validationItem) && validationItem[0] !== '$') {
          if (this.validator[validationItem] === false) return validationMap[validationItem](this.validator)
        }
      }

      return ''
    }
  },
  data() {
    return {
      isShowPassword: false,
    }
  }
}
</script>

<style scoped lang="scss">

.form-input {
  position: relative;

  &__error-text {
    position: absolute;
    top: 2px;
    right: 0;

    min-height: 17px;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;

    color: #FF0000;
  }
  &--error {
    .form-input__label {
      color: red;
    }
  }
  &__label {
    position: relative;
    display: flex;
    flex-direction: column;

    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    color: #000000;
  }

  &__input {
    outline: none;
    height: 48px;

    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    border: 0.5px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 4px;

    padding-right: 35px;
    padding-left: 14px;
    margin-top: 2px;

    transition: .1s;

    &--password{
      font-family: monospace;
      font-size: 20px;
      letter-spacing: 6px;

      @-moz-document url-prefix() {
        font-family: monospace !important;
        font-size: 12px !important;
      }
    }

    &:focus {
      border-color: #7B8DFF;
    }
    &:read-only {
      background: #E8E8E8;
    }
    &::placeholder {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      align-items: center;
      color: #C4C4C4;

    }
  }

  &__eye {
    position: absolute;
    right: 13px;
    bottom: 12px;
    width: 20px;
    height: 18px;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.form-input--error {
  .form-input__input {
    border-color: red;
  }
}
</style>
