import store from '@/store/index'
import {myCoursesRepository} from "@/app/user/myCourses/myCourses.repository";
import {notificationsHelper} from "@/helpers/notifications.helper";
import cookie from "js-cookie";

class MyCoursesService {

    async buyCourse(courseId) {
        try {

            let utmInfo = null
            if(cookie.get('utmInfo')) {
                utmInfo = {
                    params: JSON.parse(cookie.get('utmInfo'))
                }
            }

            const data = await myCoursesRepository.buyCourse(courseId, utmInfo)
            window.ym(process.env.VUE_APP_YM_ID,'reachGoal','course-payment')
            window.open(data.formUrl, '_self')
            return data
        } catch (error) {
            console.log(error)
            notificationsHelper.fromHttpError(error, {
                base: true,
                backend: {find: 'This user already has this course', set: 'Вы уже купили этот курс'}
            })
            throw error
        }
    }

    async getMyCourses() {
        try {
            const myCourses = await myCoursesRepository.getMyCourses()


            let newMyCourses = myCourses.map( course => {
                return this.getProgressByCourse(course.id).then(data => ({
                    ...data, ...course,picture:`${store.state.host}/common/courses/${course.id}/picture`
                }))
            })

            newMyCourses = await Promise.all(newMyCourses)
            store.commit('myCourses.setMyCourses', newMyCourses)

            return newMyCourses
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getProgressByCourse(courseId) {
        try {
            const progress = await myCoursesRepository.getProgressByCourse(courseId)
            return progress
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}

export const myCoursesService = new MyCoursesService()
