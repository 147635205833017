<template>
<div class="course-loading__wrapper">
  <course-control/>
  <course-header-info/>
</div>
</template>

<script>

import CourseHeaderInfo from "./components/CourseHeaderInfo";
import CourseControl from "./components/CourseControl";
export default {
  name: "CourseLoading",
  components:{
    CourseHeaderInfo,
    CourseControl
  }
}
</script>

<style scoped>

</style>