import {certificatesRepository} from "@/app/admin/certificates/certificates.repository";
import {objectCamelToUnderscore} from "@/utils/HTTPDataParser";
import {notificationsHelper} from "@/helpers/notifications.helper";
import store from "@/store/index";

class CertificatesService {
    
    async getCertificates() {
        try {
            return await certificatesRepository.getCertificates()
        } catch (error) {
            console.log(error)
            throw error
        }
    }
    
    async createCertificate(data) {
        try {
            const formData = new FormData()
            
            const ignoredFields = new Set(['template', 'userTtf', 'courseTtf','dateTtf'])
            let temp = objectCamelToUnderscore(data, ignoredFields)
            if (!temp.user_font_name) {
                delete temp.user_font_name
            }
            if (!temp.course_font_name) {
                delete temp.course_font_name
            }
            if (!temp.date_font_name) {
                delete temp.date_font_name
            }
            formData.append('data', JSON.stringify(temp))
    
            formData.append('certificate_template', data.template)
            if (data.userTtf) {
                formData.append('user_font', data.userTtf)
            }
            if (data.courseTtf) {
                formData.append('course_font', data.courseTtf)
            }
            if (data.dateTtf) {
                formData.append('date_font', data.dateTtf)
            }
            
            const response = await certificatesRepository.createCertificate(formData)
            notificationsHelper.success('Шаблон сертификата успешно создан')
            return response
        } catch (error) {
            console.log(error)
            notificationsHelper.fromHttpError(error, { base: true, backend: true })
            throw error
        }
    }
    
    async updateCertificate(data) {
        try {
            const formData = new FormData()
            
            if (data.template) {
                formData.append('certificate_template', data.template)
            }
            if (data.userTtf) {
                formData.append('user_font', data.userTtf)
                delete data.userFontName
            } else if (!store.state.certificates.defaultFonts.find(item => item === data.userFontName)) {
                delete data.userFontName
            }
            if (data.courseTtf) {
                formData.append('course_font', data.courseTtf)
                delete data.courseFontName
            } else if (!store.state.certificates.defaultFonts.find(item => item === data.courseFontName)) {
                delete data.courseFontName
            }
            if (data.dateTtf) {
                formData.append('date_font', data.dateTtf)
                delete data.dateFontName
            } else if (!store.state.certificates.defaultFonts.find(item => item === data.dateFontName)) {
                delete data.dateFontName
            }
    
            const ignoredFields = new Set(
                ['id', 'template', 'userTtf', 'courseTtf', 'dateTtf', 'userPathToFont', 'datePathToFont',
                        'name', 'coursePathToFont', 'pathToTemplate'])
            formData.append('data', JSON.stringify(objectCamelToUnderscore(data, ignoredFields)))
    
            const response = await certificatesRepository.updateCertificate(data.id, formData)
            notificationsHelper.success('Шаблон сертификата успешно обновлен')
            return response
        } catch (error) {
            console.log(error)
            notificationsHelper.fromHttpError(error, { base: true, backend: true })
            throw error
        }
    }
    
    async deleteCertificate(certificateId) {
        try {
            const data = await certificatesRepository.deleteCertificate(certificateId)
            notificationsHelper.success('Шаблон сертификата успешно удален')
            return data
        } catch (error) {
            console.log(error)
            notificationsHelper.fromHttpError(error, { base: true, backend: true })
            throw error
        }
    }
    
    async makeTestPdf(certificateId, data) {
        try {
            const blob = await certificatesRepository.makeTestPdf(certificateId, data)
            return window.URL.createObjectURL(blob)
        } catch (error) {
            console.log(error)
            throw error
        }
    }
    
    async getDefaultFonts() {
        try {
            if (store.state.certificates.defaultFonts.length > 0) {
                return store.state.certificates.defaultFonts
            } else {
                const data = await certificatesRepository.getDefaultFonts()
                store.commit('certificates.setDefaultFonts', data.fontnames)
                return data.fontnames
            }
        } catch (error) {
            console.log(error)
            throw error
        }
    }
    
}

export const certificatesService = new CertificatesService()
