<template>
<div class="lesson-btn">
  <img src="@/assets/img/button/add-lesson.svg" alt="">
  <div class="lesson-btn__text">{{label}}</div>
</div>
</template>

<script>
import imgToSvg from "../../../../../../../../assets/js/imgToSvg";

export default {
  name: "AddLessonBtn",
  props:{
    label:String
  },
  mounted() {
    imgToSvg(".lesson-btn img")
  }
}
</script>

<style lang="scss">
.lesson-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 42px;

  height: 50px;
  width: max-content;

  border: 2px solid #4EE1A3;
  box-sizing: border-box;
  border-radius: 120px;

  cursor: pointer;

  transition: 0.3s;

  &:hover {
    background: #4EE1A3;

    svg path {
      fill: #FFFFFF;
      transition: all .1s ease-in;
    }

    .lesson-btn__text {
      color: #FFFFFF;
    }
  }

  &__text{
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #1B1B1B;
    margin-left: 10px;
    transition: 0.2s;
  }

}
</style>