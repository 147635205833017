<template>
  <app-popover @click-yes="$emit('deleteLesson')">
    <p slot="title">{{title}}</p>
    <div slot="icon">
      <div class="button-remove">
        <img src="@/assets/img/courses/program/button/icon-delete.svg" alt="delete">
        <div class="button-title">{{ buttonText }}</div>
      </div>
    </div>
  </app-popover>
</template>

<script>
import AppPopover from "../../../../../../../../components/AppPopover";

export default {
  name: "DeleteLessonBtn",
  props:{
    title: {type: String, default: 'Удалить урок?'},
    buttonText: { type: String, default: 'Удалить урок' }
  },
  components: {
    AppPopover
  }
}
</script>

<style lang="scss" scoped>
.button-remove {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 222px;
  height: 50px;

  margin-right: 5px;
  background: #FFFFFF;
  border: 2px solid #FF2C2C;
  box-sizing: border-box;
  border-radius: 120px;

  cursor: pointer;

  transition: 0.3s;

  &:hover {
    box-shadow: 0 0 15px rgba(255, 44, 44, 0.3);
  }
}

.button-title {
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  margin-left: 15px;
}

</style>