import http from "@/app/user/config/user-axios.config";

class CoursesRepository {

    async getCategories() {
        const response = await http.get(`/common/categories`)
        return response.data
    }
    
    async getCourses() {
        const response = await http.get(`/common/courses`)
        return response.data
    }
    
    async getCourseInfo(courseId) {
        const response = await http.get(`/common/courses/${courseId}`)
        return response.data
    }
    async getAuthorCertificatesById(courseId) {
        const response = await http.get(`/common/courses/${courseId}/author_certificates`)
        return Promise.resolve(response.data)
    }
    async getAuthorCertificatePhotoById(courseId, certificateId) {
        const response = await http.get(`/common/courses/${courseId}/author_certificates/${certificateId}`,{responseType: 'blob'})
        return Promise.resolve(response.data)
    }

    // async getCourseLessons(courseId) {
    //     const response = await http.get(`/common/courses/${courseId}/lessons`)
    //     return response.data
    // }

    async getThemes (courseId) {
        const response = await http.get(`/common/courses/${courseId}/themes`)
        return response.data.data
    }
    
    async downloadCertificate(courseId) {
        const response = await http.post(`/spa/certificates/${courseId}/make`, null, {responseType: 'blob'})
        return response.data
    }

    async buyСourseWithoutAuthorization(courseId,email,params) {
        const response = await http.post(`/common/courses/${courseId}/buy`, {email},params)
        return response.data
    }

    async finishPaymentForCourse(userId) {
        const response = await http.post(`/common/courses/tmp_user/${userId}`)
        return response.data
    }

    async deleteTmpUser(userId){
        const response = await http.delete(`/common/courses/tmp_user/${userId}`)
        return Promise.resolve(response.data)
    }

    async getLessonIntro(courseId){
        const response = await http.get(`/common/courses/${courseId}/entry_lesson`)
        return response.data
    }

    async getPreviewForLessonIntro(courseId) {
        try {
            const response = await http.get(`/common/courses/${courseId}/entry_lesson/thumbnail`, {responseType: 'blob'})

            return {
                file: response.data,
            }
        } catch (error) {
            console.log(error)
        }

    }
}

export const coursesRepository = new CoursesRepository()
