<template>
  <div>
    <el-dialog
        :visible="visible"
        width="500px"
        top="200px"
        :before-close="handleClose"
        class="buy-course-popup">
      <template slot="title">
        <div class="buy-course-popup__header">
          <img id="buy-course-popup-icon" src="@/assets/img/user/icon-info-modal.svg" alt="icon">
          <div class="buy-course-popup__title">Оплата курса</div>
        </div>
        <el-divider/>
      </template>
      <div class="buy-course-popup__content">

        <div class="buy-course-popup__info-aveabled">
          Курс в разработке!<br>Скоро будет доступен для покупки
        </div>
      </div>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "NotAvailableCourse",
  computed:{
    visible() {
      return this.$store.state.myCourses.showNotAvailableCourse
    }
  },
  methods:{
    handleClose(){
      this.$router.replace({'query': null});
      this.$store.commit('myCourses.setShowNotAvailableCourse', false)
    }
  }
}
</script>

<style scoped>

</style>