<template>
  <div class="course-header-info__wrapper">
    <div class="course-header-info__title">
      <span>A</span>
      <span>Y</span>
      <span>A</span>
      <span>S</span>
      <span>c</span>
      <span>h</span>
      <span>o</span>
      <span>o</span>
      <span>l</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "CourseHeaderInfo",
}
</script>

<style lang="scss">
.course-header-info {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 28px;
    margin-bottom: 32px;
    background: rgba(120, 159, 255, 0.15);
    border-radius: 20px;

    min-height: 300px;
    overflow: hidden;

  }
  &__title {
    position: relative;
    font-family: Montserrat, sans-serif;
    font-size: 36px;
    color: #999999;
    margin-bottom: 48px;
    font-weight: 500;
    width: 200px;

    span{
      display: inline-block;
      position: relative;
      animation: flip 3s infinite;
      top: 0;
    }

    span:nth-child(2){
      animation-delay: 0.2s;
    }
    span:nth-child(3){
      animation-delay: 0.4s;
    }
    span:nth-child(4){
      animation-delay: 0.6s;
    }
    span:nth-child(5){
      animation-delay: 0.8s;
    }
    span:nth-child(6){
      animation-delay: 0.9s;
    }
    span:nth-child(7){
      animation-delay: 1.1s
    }
    span:nth-child(8){
      animation-delay: 1.3s;
    }
    span:nth-child(9){
      animation-delay: 1.3s;
    }
    @keyframes flip {
      0% {
        top: 0;
      }
      10%{
        top: -15px;
        color: rgba(20, 84, 242, 0.82);
      }
      30%{
        color: #999999;
        top: 0;
      }
    }


    //span {
    //  padding: 12px 48px;
    //  transform: translate(-50%, -50%);
    //  color: #ffffff;
    //
    //  background: linear-gradient(to right, #4d4d4d 0, white 10%, #4d4d4d 20%);
    //
    //  -webkit-background-clip: text;
    //  -webkit-text-fill-color: transparent;
    //
    //  animation: shine 3s infinite linear;
    //  animation-fill-mode: forwards;
    //
    //  font-weight: 600;
    //  font-size: 36px;
    //
    //  @keyframes shine {
    //    0% {
    //      background-position: 0
    //    }
    //    60% {
    //      background-position: 180px;
    //    }
    //    100% {
    //      background-position: 280px;
    //    }
    //  }
    //}
  }
}

</style>