<template>
  <div class="course-button"
       :class="{'course-button--active': selectedCourseId && selectedCourseId === course.id}"
       @click="$emit('click')">

    <span>{{ course.name }}</span>
    <span class="course-button__image-wrapper" @click.stop="clickEditCourse">
        <img src="../../../../../assets/img/button/edit.svg" alt="edit">
      </span>
    <app-popover @click-yes="$emit('delete-course')">
      <p slot="title">Удалить курс?</p>

      <div slot="icon">
        <div class="course-button__image-wrapper">
          <img src="@/assets/img/button/delete.svg" alt="delete">
        </div>
      </div>
    </app-popover>

    <edit-course-modal ref="EditCourseModal"
                       :loading="loadingModal"
                       @edit-course="editCourse"/>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {courseService} from "../../course.service";

import imgToSvg from "@/assets/js/imgToSvg"
import AppPopover from "../../../../../components/AppPopover";
import EditCourseModal from "./modals/EditCourseModal";

export default {
  name: "CourseItem",
  components: {
    AppPopover,
    EditCourseModal
  },
  props: {
    course: {
      type: Object
    }
  },
  data() {
    return {
      loadingModal: false
    }
  },
  computed: {
    ...mapGetters(['selectedCourseId'])
  },
  mounted() {
    imgToSvg('.course-button__image-wrapper img')
  },
  methods: {
    ...mapMutations(['selectCategory','selectCourseId']),
    clickEditCourse() {
      this.$refs.EditCourseModal.openModal(this.course)
    },
    async editCourse(editCourse) {
      this.loadingModal = true
      await courseService.updateCategoriesCourse(editCourse)
      this.course.categories = editCourse.categories

      this.selectCategory({id:editCourse.categories[editCourse.categories.length -1]})
      this.selectCourseId(this.course.id)

      this.loadingModal = false
      this.$refs.EditCourseModal.closeModal()
    }
  }
}
</script>

<style lang="scss">
.course-button {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  padding-left: 32px;
  padding-right: 32px;

  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  white-space: nowrap;

  color: #121212;
  background: #FFFFFF;


  width: max-content;
  height: 54px;

  border: 2px solid #1454F2;
  box-sizing: border-box;
  border-radius: 120px;

  cursor: pointer;

  transition: all .1s ease-in;

  text-decoration: none;

  span:first-child {
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__image-wrapper {
    display: inline-flex;
    height: max-content;
    width: 16px;
    margin-left: 15px;
    margin-bottom: 4px;
  }

  &--active {
    background: #1454F2;
    color: #FFFFFF;

    svg path {
      fill: #FFFFFF;
      transition: all .1s ease-in;
    }
  }

  &--edit {
    background: #FFFFFF;
  }

}
</style>

<style lang="scss">
.course-button {
  &--active {
    svg path {
      fill: #FFFFFF;
      transition: all .1s ease-in;
    }
  }

  .el-popover__reference {
    .course-button__image-wrapper{
      margin-top: 3px;
    }
  }
  .el-input__inner {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    padding-left: 0px;
    border: none;
    border-radius: 50px;
  }
}
</style>