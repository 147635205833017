import axios from 'axios'
import store from '@/store'
// import cookie from 'js-cookie'
import {decodeData, encodeData} from '@/utils/HTTPDataParser'
import {authService} from '@/app/admin/auth/auth.service'

const http = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_HOST + '/admin'
})

http.defaults.headers.common['Content-Type'] = 'application/json'
// http.defaults.headers.common['Set-cookie'] = cookie.get('test')

http.interceptors.request.use(async function (config) {
    const token = store.state.admin.adminAccessToken

    if (store.state.admin.isAdminLoggedIn) {
        config.headers.common['Authorization'] = `Bearer ${token}`
    
        const exp = store.state.admin.jwtExp
    
        if (config.url !== '/refresh' && config.url !== '/logout') {
            if (exp - Date.now() <= process.env.VUE_APP_REMAINING_TIME_TO_REFRESH_TOKEN)  {
                await authService.refreshToken()
            
                const token = store.state.user.accessToken
            
                config.headers.common['Authorization'] = `Bearer ${token}`
                config.data = decodeData(config.data)
            
                return config
            } else {
                config.data = decodeData(config.data)
                return config
            }
        } else {
            config.data = decodeData(config.data)
            return config
        }
    } else {
        config.data = decodeData(config.data)
        return config
    }
})

http.interceptors.response.use((response) => {
    if (response.headers.contentType === 'application/json' || response.headers['content-type'] === 'application/json')
        response.data = encodeData(response.data)
    return response
}, (error) => {
    if (error.response?.status === 401)
        authService.logout()
    throw error
})

export default http
