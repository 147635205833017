<template>
  <div class="lesson-info">

    <div class="lesson-info__lesson-header"
         :class="{'lesson-info__lesson-header--active-mobile': showLesson && windowWidth <= 400}"
         @click="handleClickLesson">

      <div class="lesson-info__lesson-number">
        <span v-if="numberLesson < 10">0</span>
        {{ numberLesson }}
      </div>

      <div class="lesson-info__lesson-name">
        <span>{{ numberLesson }}.</span>
        {{ lessonInfo.name }}
      </div>
      <div class="lesson-info__arrow"
           :class="{'lesson-info__arrow--active':showLesson}">
        <img src="@/assets/img/courses/program/arrow.svg" alt="arrow">
      </div>

    </div>
    <el-collapse-transition>
      <div v-if="showLesson" class="lesson-info__content">

        <div class="lesson-info__transition-box ck-content" style="margin-bottom: 10px" v-html="lessonInfo.description"></div>

        <div class="lesson-info__transition-box">
          <div class="lesson-info__transition-header"
               v-for="(item,index) in lessonInfo.attachments.filter(attach => attach.type !== 'video')" :key="index">
            <img src="@/assets/img/courses/program/text-file.svg" alt="arrow">
            <div class="lesson-info__transition-box-title">{{ item.title }}</div>
          </div>
        </div>

        <div v-if="videoAttachment">

<!--          <div v-if="$route.name === 'course-info'" class="lesson-info__video-wrapper">-->
<!--            <div class="lesson-info__video-description">{{ lessonInfo.subject }}</div>-->
<!--          </div>-->

          <div v-if="isAccessToken" class="lesson-info__transition-box">
            <template>
              <video-lesson
                  :lesson="lessonInfo"
              />
            </template>
          </div>
        </div>

        <div v-if="lessonInfo.question" class="lesson-info__transition-box">
          <div class="lesson-info__transition-header">
            <img src="@/assets/img/courses/program/practical-file.svg" alt="arrow">
            <div class="lesson-info__transition-box-title">Тест</div>
          </div>
        </div>

      </div>
    </el-collapse-transition>

  </div>
</template>

<script>
import imgToSvg from "@/assets/js/imgToSvg";
import 'video.js/dist/video-js.css'

import {vimeoLink} from "@/utils/validators";

import VideoLesson from "@/app/user/lessonsCourse/components/VideoLesson.vue";
import {youtubeLink} from "../../../../../../utils/validators";


export default {
  name: "LessonInfo",
  components: {
    VideoLesson
  },
  props: {
    lessonInfo: Object,
    numberLesson: [Number, String],
    purchased: {
      type: Boolean, default: false,
    },
    showLesson: {type: Boolean, default: false}
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth
    },
    videoAttachment() {
      return this.lessonInfo.attachments.find(item => item.type === 'video')
    },
    isAccessToken(){
      return this.$store.state.user.accessToken
    },
  },
  data() {
    return {
      linkForYouTube: '',
      vimeoId: null,
      loading: true,
      playerOptions: {
        width: '790',
        height: '500',
        muted: true,
        language: 'ru',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: ""
        }],
        poster: "/static/images/author.jpg",
      },
      vimeoOptions: {
        width: 800,
        title: false,
        byline: false,
        height: 'auto',
      }
    }
  },
  created() {
    if (this.lessonInfo.attachments) {
      const videoPath = this.videoAttachment?.path
      if (vimeoLink(videoPath)) {

        this.vimeoId = videoPath.match(/(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/|\/videos\/|video\/|)(\d+)(?:|\/\?)/)[1]

      } else if (youtubeLink(videoPath)) {

        const url = new URL(videoPath)

        if (!url.searchParams.get('v'))
          this.linkForYouTube = `https://www.youtube.com/embed${url.pathname}`
        else
          this.linkForYouTube = `${url.origin}/embed/${url.searchParams.get('v')}`

        this.linkForYouTube += '?rel=0&showinfo=0&iv_load_policy=3&modestbranding=1&controls=1'
      } else if (videoPath) {
        this.playerOptions.sources[0].src = `${process.env.VUE_APP_BACKEND_HOST}/watch?path=${videoPath}`
      }
    }
  },
  mounted() {
    imgToSvg('.lesson-info__arrow img')
  },
  methods: {
    handleClickLesson() {
      if (this.showLesson)
        this.$emit('selectedLesson', null)
      else
        this.$emit('selectedLesson', this.lessonInfo.id)
    },
    transformYoutubeLink: function (link) {
      return link.replace('watch?v=', 'embed/')
    },
    titleAttachments(item) {
      if (vimeoLink(item.path))
        return 'Vimeo'
      else
        return 'Video'
    }
  }

}
</script>

<style scoped lang="scss">
@import "../../../../../../assets/scss/_video-player.css";
</style>

<style lang="scss">
.vp-title-header {
  display: none !important;
}

.ytp-show-cards-title {
  color: red;
}

.lesson-info {
  margin-bottom: 10px;

  background: #F7F7F7;
  box-sizing: border-box;
  border-radius: 18px;

  color: #121212;

  &__content {
    padding-bottom: 20px;
  }

  &__video {
    margin: 0 auto 16px auto;
    width: 800px;

    iframe {
      border-radius: 4px;
    }
  }

  &__lesson-header {
    display: flex;
    align-items: center;
    position: relative;

    padding: 20px 26px;
    box-sizing: border-box;
    cursor: pointer;

    transition: 0.3s;

    &--active-mobile {
      padding: 20px 20px 11px 20px !important;
    }
  }

  &__lesson-number {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #ffffff;

    height: 40px;
    min-width: 40px;

    margin-right: 20px;

    border-radius: 100%;
    background: #222222;

    @media screen and (max-width: 400px){
      display: none;
    }
  }

  &__lesson-name {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #222222;
    margin-right: 6px;

    transition: 0.2s;

    span {
      display: none;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin-right: 12px;
    }
  }

  &__arrow {
    margin-left: auto;

    svg {
      transition: 0.2s;
      width: 20px;
    }

    &--active > svg {
      transform: rotate(180deg);
    }
  }

  &__transition-box {
    padding: 0 26px;
  }

  &__transition-header {
    display: flex;
    align-items: center;

    height: 50px;

    margin-bottom: 10px;
    padding-left: 15px;

    border: 0.5px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 100px;

    transition: 0.1s;

    img {
      margin-right: 15px;
    }
  }

  &__transition-box-title {
    font-family: 'Montserrat', 'sans-serif';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .video-js {
    border-radius: 4px;

    .vjs-poster {
      border-radius: 4px;
    }


    .vjs-control-bar {
      border-radius: 4px;
    }
  }

  &__video-wrapper {
    padding: 30px 30px 30px 92px;
    border-top: 1px solid #AEB3B9;

    transition: 0.2s;
  }

  &__video-description {
    font-family: Gilroy, Montserrat, sans-serif;
    font-size: 16px;
    line-height: 150%;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    color: #222222;
    word-break: break-word;
    white-space: pre-wrap;
  }

  @media screen and (max-width: 700px){
    &__lesson-name {
      font-size: 20px;
    }
    &__video-wrapper {
      padding: 24px 24px 24px 92px;
    }
  }
  @media screen and (max-width: 600px){
    &__lesson-header {
      padding: 16px 20px;
    }
    &__video-description {
      font-weight: 500;
    }
    &__lesson-name {
      font-size: 18px;
    }
    &__video-wrapper {
      padding: 16px 20px;
    }
    &__arrow {
      svg {
       width: 12px;
      }
    }
  }
  @media screen and (max-width: 400px){
    &__lesson-name {
      display: flex;
      align-items: center;

      font-size: 16px;
      line-height: 18px;

      span {
        display: inline-block;
      }
    }
    &__video-wrapper {
      border-top: none;
      padding: 0 40px 20px 43px;
    }
    &__lesson-header {
      padding: 20px;
    }
  }

}

</style>
