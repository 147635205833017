<template>
  <div v-loading="loading"
       class="lesson text-lesson-wrapper">
    <el-collapse
        ref="collapse"
        @change="handleChangeCollapse">
      <el-collapse-item :title="`Урок №${lesson.position}: ${lesson.name}`" ref="collapseItem">
        <div
            @drag.stop
            @dragstart.stop
            @dragover.stop
            @pointerdown.stop
            @mousedown.stop
            @touchstart.stop
        >
          <text-lesson :lesson="lesson" ref="textLesson"/>

          <practical-lesson :lesson="lesson" ref="practicalLesson"/>

          <div class="text-lesson__action-buttons" style="margin-top: 10px">

            <div class="text-lesson__save-btn"
                 :class="{'text-lesson__save-btn--disabled': $v.$error}"
                 @click="handleClickSave">
              <div class="text-lesson__save-btn-title">Сохранить</div>
            </div>

            <delete-lesson-btn @deleteLesson="$emit('deleteLesson', lesson.id)"/>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import DeleteLessonBtn from "@/app/admin/course/components/courseEditor/components/CourseProgramEditor/components/DeleteLessonBtn.vue";
import TextLesson from "@/app/admin/course/components/courseEditor/components/CourseProgramEditor/components/TextLesson.vue";
import PracticalLesson from "@/app/admin/course/components/courseEditor/components/CourseProgramEditor/components/PracticalLesson.vue";
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import {courseService} from "@/app/admin/course/course.service";

export default {
  name: 'lesson',
  mixins: [validationMixin],
  components: {
    DeleteLessonBtn,
    TextLesson,
    PracticalLesson
  },
  props: {
    lesson: { type: Object },
    themeId: { type: Number }
  },
  data() {
    return {
      loading: false,
      isRemoveVideo: false
    }
  },
  created() {
    this.$v.$touch()
  },
  methods: {
    handleChangeCollapse(value) {
      if (value.length) {
        setTimeout(() => {
          const el = this.$refs.collapseItem.$el.getElementsByClassName('el-collapse-item__wrap')?.[0]
          el.style.overflow = 'unset'
        }, 600)
      } else {
        const el = this.$refs.collapseItem.$el.getElementsByClassName('el-collapse-item__wrap')?.[0]
        el.style.overflow = 'hidden'
      }
    },
    handleClickSave() {
      if (!this.$v.$error) {
        if (!typeof this.lesson.id || typeof this.lesson.id === 'string') {
          this.loading = true
          courseService.createLesson(this.themeId, this.lesson)
              .then((data) => {
                this.lesson.id = data.id
                return courseService.updateLesson(
                    this.themeId,
                    this.lesson,
                    this.$refs.practicalLesson.idDeletedAnswers,
                    null,
                    this.$refs.textLesson.idDeletedFiles,
                    this.isRemoveVideo
                )
              })
              .then(() => this.$notify.success({ title: 'Успех', message: 'Урок успешно создан!' }))
              .catch(() => this.$notify.error({ title: 'Ошибка', message: 'Во время создания урока возникли ошибки!' }))
              .finally(() => this.loading = false)
        } else {
          this.loading = true
          courseService.updateLesson(
              this.themeId,
              this.lesson,
              this.$refs.practicalLesson.idDeletedAnswers,
              null,
              this.$refs.textLesson.idDeletedFiles,
              this.isRemoveVideo
          )
              .then(() => this.$notify.success({ title: 'Успех', message: 'Урок успешно обновлен!' }))
              .catch((error) => {
                console.log(error)
                this.$notify.error({ title: 'Ошибка', message: 'Во время обновления урока возникли ошибки!' })
              })
              .finally(() => this.loading = false)
        }
      }
    },
    handleRemoveVideo() {
      this.isRemoveVideo = true
    }
  },
  validations: {
    lesson: {
      name: {required, minLength: minLength(2), maxLength: maxLength(164)},
      description: {required, minLength: minLength(2)},
    }
  }
}
</script>

<style lang="scss" scoped>
.text-lesson-wrapper {
  display: flex;
  flex-direction: column;

  margin-top: 17px;

  .number-lesson{
    .el-input__inner{
      position: absolute;
      width: 35px;
      height: 35px;

      left: 85px;
      top: 7px;
      padding: 0 5px;

      font-family: Montserrat, sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #121212;

    }
  }

  .el-collapse{
    border:none;
  }
  .el-collapse-item__header{
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    padding: 0 15px;


    border-radius: 100px;
    border: 1px solid #DCDFE6;
  }

  .el-input.title-lesson-input {
    .el-input__inner {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #121212;
      height: 50px;

      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 100px;
    }
  }

  .el-textarea.content-lesson-input {
    .el-textarea__inner {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #121212;
      min-height: 180px !important;

      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 20px;
    }
  }

  .text-lesson {
    display: flex;
    align-items: flex-start;

    margin-top: 10px;

    &__label {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;

      margin-bottom: 10px;
    }

    &__item {
      display: flex;
      flex-direction: column;

      margin-left: 10px;
    }

    &__text-label {
      display: flex;
      align-items: center;

      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      cursor: pointer;

      &--disabled {
        cursor: not-allowed;
        color: #C0C4CC;
      }

      img {
        margin-right: 12px;
      }
    }

    &__title-input {
      margin-top: 24px
    }

    &__content-input {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__add-file {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 245px;
      height: 50px;

      background: #407BFF;
      border-radius: 120px;

      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        bottom: 50%;
        left: 0;
        width: 100%;
        height: 0;
        background: rgba(255, 255, 255, 0.1);
      }

      &:hover:before {
        transition: 400ms;
        bottom: 0;
        height: 100%;
      }
    }

    &__add-title {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;

      color: #FFFFFF;
      margin-left: 15px;
    }

    &__action-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__save-btn {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 222px;

      background: #4EE1A3;
      border-radius: 120px;

      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        bottom: 50%;
        left: 0;
        width: 100%;
        height: 0;
        background: rgba(255, 255, 255, 0.1);
      }

      &:hover:before {
        transition: 400ms;
        bottom: 0;
        height: 100%;
      }

      &--disabled {
        background: #C4C4C4;
        cursor: not-allowed;

      }
    }

    &__save-btn-title {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;

      color: #FFFFFF;
    }

    &__btn-type-title{
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;

      color: #121212;
      padding: 13px 20px;

      width: 233px;
      height: 50px;

      border: 2px solid #4EE1A3;
      box-sizing: border-box;
      border-radius: 120px;

      cursor: default;
    }
    &__btn-type-label{
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;

      margin-bottom: 10px;
    }

  }

}
</style>
