<template>
  <div class="base-preloader"
       v-loading="true"
       element-loading-spinner="el-icon-loading"
       :style="styles"></div>
</template>

<script>
export default {
  name: "BasePreloader",
  props: {
    width: {type: String, default: '100%'},
    height: {type: String, default: 'calc(100vh - 200px)'}
  },
  computed: {
    styles() {
      return [
        { width: this.width },
        { height: this.height }
      ]
    }
  }
}
</script>

<style lang="scss">
.base-preloader {
  .el-loading-mask {
    .el-icon-loading {
      font-size: 26px;
      color: #1454F2;
    }
  }
}
</style>
