import {coursesRepository} from "@/app/user/courses/courses.repository";
import {notificationsHelper} from "@/helpers/notifications.helper";
import store from "@/store/index";
import router from "@/router/index";
import cookie from "js-cookie";

class CoursesService {

    async init() {
        try {
            if (store.state.courses.init) {
                return 0
            } else {
                store.commit('courses.setInit', true)
            }

            const categoriesPromise = this.getCategories()
            const coursesPromise = this.getCourses()

            const responses = await Promise.all([categoriesPromise, coursesPromise])
            const categories = responses[0]
            const courses = responses[1]

            store.commit('courses.pushCategories', categories)
            store.commit('courses.pushCourses', courses)

            return null

        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getCategories() {
        try {
            return await coursesRepository.getCategories()
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getCourses() {
        try {
            const courses = await coursesRepository.getCourses()
            return courses.map(course => ({
                ...course,
                picture: `${store.state.host}/common/courses/${course.id}/picture`,
                info: null
            }))
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getThemes(courseId) {
        try {
            const themes = await coursesRepository.getThemes(courseId)

            return themes.map(item => ({
                ...item,
                lessons: item.lessons.map(lesson => ({
                    ...lesson,
                    attachments: lesson?.attachments || [],
                    answers: lesson?.answers || []
                }))
            }))
        } catch (error) {
            return []
        }
    }

    async getCourseInfo(courseId) {
        try {
            const course = store.state.courses.courses.find(item => item.id === courseId)
            if (course && course.info) {
                return course.info
            } else {
                const infoPromise = coursesRepository.getCourseInfo(courseId)
                const themesPromise = this.getThemes(courseId)
                let certificatesPhoto = this.getAuthorCertificatesById(courseId)

                const videoInto = this.getLessonIntro(courseId)

                const responses = await Promise.all([infoPromise, themesPromise, certificatesPhoto,videoInto])

                let info = responses[0]

                const themes = responses[1]
                const testsCount = responses[1].testsCount
                certificatesPhoto = responses[2]

                info.thanksToCourse = info.thanksToCourse.map(value => {
                    return {value}
                })

                if (course) {
                    info = {
                        ...info,
                        videoIntro: responses[3],
                        author: info.author ? {
                            ...info.author,
                            picture: `${store.state.host}/common/courses/${courseId}/author/picture`,
                            certificatesPhoto
                        } : null,
                        reviews: info.reviews ? info.reviews.map(item => ({
                            ...item,
                            picture: `${store.state.host}/common/courses/${courseId}/review/${item.id}/picture`
                        })) : [],
                        themes: themes || [],
                        testsCount
                    }
                } else {
                    info = {
                        categories: info.categories,
                        color: info.color,
                        description: info.description,
                        id: info.id,
                        info: {
                            ...info,
                            reviews: info.reviews ? info.reviews.map(item => ({
                                ...item,
                                picture: `${store.state.host}/common/courses/${courseId}/review/${item.id}/picture`
                            })) : [],
                            themes: themes || [],
                            testsCount,
                            author: info.author ? {
                                ...info.author,
                                picture: `${store.state.host}/common/courses/${courseId}/author/picture`,
                                certificatesPhoto
                            } : null
                        },
                        name: info.name,
                        price: info.price,
                        picture: `${store.state.host}/common/courses/${info.id}/picture`,
                        tasksCount: info.tasksCount,
                        videosCount: info.videosCount,
                    }
                }

                store.commit('course.setCourseInfo', info)
                return info
            }
        } catch (error) {
            router.replace('/404')
            throw error
        }
    }

    async getAuthorCertificatesById(courseId) {
        const response = await coursesRepository.getAuthorCertificatesById(courseId)

        let promises = []

        response.forEach(item => {
            promises.push(this.getAuthorCertificatePhotoById(courseId, item.id).then(photo => {
                return {
                    ...item,
                    photo
                }
            }))
        })

        promises = await Promise.all(promises)

        const certificatesPhoto = [{}, {}, {}]

        promises.forEach((item, i) => {
            certificatesPhoto[i] = item
        })

        return certificatesPhoto
    }

    async getAuthorCertificatePhotoById(courseId, certificateId) {
        const response = await coursesRepository.getAuthorCertificatePhotoById(courseId, certificateId)
        return response
    }

    async downloadCertificate(courseId) {
        try {
            const blob = await coursesRepository.downloadCertificate(courseId)
            const link = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = link
            a.setAttribute('download', 'certificate.pdf')
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(link)
            return link
        } catch (error) {
            console.log(error)
            router.push('/404?cert')
            throw error
        }
    }

    async buyСourseWithoutAuthorization(courseId, email) {
        try {
            let utmInfo = null
            if (cookie.get('utmInfo')) {
                utmInfo = {
                    params: JSON.parse(cookie.get('utmInfo'))
                }
            }

            const data = await coursesRepository.buyСourseWithoutAuthorization(courseId, email, utmInfo)

            window.ym(process.env.VUE_APP_YM_ID, 'reachGoal', 'course-payment')
            localStorage.setItem('userInfo', JSON.stringify({isTemporary: data.isTemporary, email: email}))
            window.open(data.formUrl, '_self')
            return data
        } catch (error) {
            console.log(error)
            notificationsHelper.fromHttpError(error, {
                base: `Курс уже был приобретен пользователем ${email}`,
                backend: false
            })
            throw error
        }
    }

    async finishPaymentForCourse(userId) {
        try {
            const data = await coursesRepository.finishPaymentForCourse(userId)
            return data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async deleteTmpUser(userId) {
        const response = await coursesRepository.deleteTmpUser(userId)
        return Promise.resolve(response.data)
    }

    async getLessonIntro(courseId) {
        try {
            const promises = []
            promises.push(coursesRepository.getLessonIntro(courseId))
            promises.push(coursesRepository.getPreviewForLessonIntro(courseId))

            const responses = await Promise.all(promises)

            return {
                isVisible: responses[0].isVisible,
                path: responses[0].path,
                preview: responses[1].file,
            }
        } catch (error) {
            console.log(error)
            return {
                path:'',
                filePreview: null
            }
        }
    }
}

export const coursesService = new CoursesService()
