import store from '@/store'
import {notificationsHelper} from '@/helpers/notifications.helper'
import {adminRepository} from '@/app/admin/adminUsers/admin.repository'

class AdminService {

    async getAdminInfo() {
        try {
            const data = await adminRepository.getAdminInfo()
            store.commit('setAdminInfo', data.me)
            return data
        } catch (error) {
            notificationsHelper.fromHttpError(error, { base: false, backend: false })
            return Promise.reject(error)
        }
    }
    async getAdminsPage(pagination) {
        try {
            const data = await adminRepository.getAdminsPage(pagination)
            return data
        } catch (error) {
            notificationsHelper.fromHttpError(error, { base: false, backend: false })
            return Promise.reject(error)
        }
    }
    async addAdmin(newAdmin) {
        try {
            const data = await adminRepository.addAdmin(newAdmin)
            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {
                base: false,
                backend: { find: 'Admin with this email already exists', set: 'Админ с таким email уже существует' } })
            return Promise.reject(error)
        }
    }

    async updateAdmin(admin) {
        try {
            let newAdmin = {}
            Object.keys(admin).forEach(key => {
                if(admin[key])
                newAdmin[key] = admin[key]
            })

            const data = await adminRepository.updateAdmin(newAdmin)
            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {
                base: false,
                backend: { find: 'Admin with this email already exists', set: 'Админ с таким email уже существует' } })
            return Promise.reject(error)
        }
    }
    async deleteAdmin(adminId) {
        try {

            const data = await adminRepository.deleteAdmin(adminId)
            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, { base: false, backend: false })
            return Promise.reject(error)
        }
    }
    
}

export const adminService = new AdminService()
