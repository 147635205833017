<template>
  <div class="text-lesson__text-file">
    <img v-if="type === 'video'" class="text-lesson__text-file-icon" src="@/assets/img/courses/program/media-file.svg" alt="video">
    <img v-else class="text-lesson__text-file-icon" src="@/assets/img/courses/program/text-file.svg" alt="file">

    <div class="text-lesson__text-input">
      <label class="text-lesson__input-label" :for="`text-file${index}-${keyItem}`">
        <img src="@/assets/img/courses/program/add-file.svg" alt="video">
        <input v-if="type === 'video' && (file.file || file.path)"
               v-model="file.title"
               class="text-lesson__text-file--input-title"
               type="text">
        <span v-else>{{ nameFile }}</span>
      </label>

      <input class="input-wrapper__input-add"
             :id="`text-file${index}-${keyItem}`"
             type="file"
             :accept="accept"
             ref="inputText"
             style="display: none"
             @change="loadFile">
    </div>

    <div class="text-lesson__icon-remove"
         @click="fileRemove">
      <img src="@/assets/img/courses/program/icon-remove.svg" alt="delete">
    </div>

  </div>
</template>

<script>
import imgToSvg from "@/assets/js/imgToSvg"

export default {
  name: "TextLessonInputFile",
  props:{
    index: [Number, String],
    keyItem: Number,
    file: [Object,File],
    type: String,
    accept: String
  },
  computed: {
    nameFile () {
      if (this.file) {
        if (this.file.title) {
          return this.file.title
        } else if (this.file.file) {
          return this.file.file.name
        } else {
          return 'Выберите файл'
        }
      } else {
        return 'Выберите файл'
      }
    }
  },
  mounted() {
    imgToSvg('.text-lesson__icon-remove img')
  },
  methods: {
    loadFile() {
      if(this.$refs.inputText.files[0]) {
        this.$emit('loadFile', {
          title: this.$refs.inputText.files[0].name,
          file: this.$refs.inputText.files[0],
          index: this.index
        })
      }
    },
    fileRemove() {
      this.preview = null
      this.$refs.inputText.value = ""
      this.$emit('deleteFile',this.index)
    },
  }
}
</script>

<style lang="scss">
.text-lesson {
  &__text-file {
    display: flex;
    align-items: center;

    width: 545px;
    height: 50px;
    padding: 0px 18px 0px 15px;

    margin-bottom: 15px;

    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 100px;

    &--input-title {
      padding: 5px 10px;
      width: 100%;
      border-radius: 5px;
      border: 1px solid #D9D9D9;
    }
  }

  &__text-file-icon {
    margin-right: 30px;
  }

  &__text-input {
    width: 80%;
  }

  &__input-label {
    width: 100%;
    display: flex;
    align-items: center;

    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    cursor: pointer;
    color: #121212;

    img {
      margin-right: 12px;
    }
  }
  &__icon-remove {
    margin-left: auto;
    height: 14px;

    svg path {
      fill: #FF2C2C;
      transition: all .1s ease-in;
    }

    cursor: pointer;
  }

}
</style>
