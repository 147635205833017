<template>
  <base-modal
      class="edit-course-wrapper"
      :visible="isVisible"
      title="Редактировать курс"
      :loading="loading"
      @submit="handleSave"
      @close="closeModal">
    <template>
      <form-select placeholder="Выберите направление"
                   label="Направления"
                   v-model="selectedCategories"
                   :options="categories"
                   :validator="$v.selectedCategories"
      />
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "../../../../../../components/BaseModal";
import FormSelect from "../../../../../../components/FormSelect";

import {mapGetters} from "vuex";
import {validationMixin} from "vuelidate";
import {validate} from "@/utils/validators";
import {helpers} from "vuelidate/lib/validators";

export default {
  name: "EditCourseModal",
  mixins: [validationMixin],
  components: {
    BaseModal,
    FormSelect
  },
  props: {
    loading: Boolean,
  },
  computed: {
    ...mapGetters(['categories', 'selectedCategory', 'courses']),
    coursesFiltered() {
      return this.courses
          .filter(item => item.categories
              .find(catId => this.categoriesFiltered.find(cat => cat === catId))
          )
    },
    categoriesFiltered() {
      return this.selectedCategories.filter(id => !this.course.categories.find(oldId => oldId === id))
    }
  },
  data() {
    return {
      isVisible: false,

      courseId:null,
      selectedCategories: [],

      course: null
    }
  },
  methods: {
    openModal: function (course) {
      this.courseId = course.id
      this.course = course
      this.selectedCategories = course.categories

      this.isVisible = true

    },
    closeModal: function () {
      this.courseId = null
      this.selectedCategories = []
      this.isVisible = false
      this.$v.$reset()
    },
    handleSave: function () {
      if (validate(this.$v)) {
        this.$emit('edit-course', {id: this.courseId, categories: this.selectedCategories})
      }
    },
  },
  validations: {
    selectedCategories: {
      unique: helpers.withParams(
          {type: 'unique', messageName: 'Курс с таким именем уже существует'},
          (value, vm) => {
            return !vm.coursesFiltered.find(vmField => vmField.name === vm.course.name)
          }
      )
    }
  }
}
</script>

<style lang="scss">
.edit-course-wrapper {
  .form-input__label {
    span {
      text-align: left;
    }
  }

  .el-dialog {
    border-radius: 4px;
  }

  &__course {
    .form-input__input {
      border-radius: 50px;
      border: 2px solid #1454F2;
      font-family: 'Montserrat';
      font-size: 14px;
      margin-top: 3px;

      &:focus {
        border-color: #1454F2;
      }
    }
  }

  .el-select {
    width: 100%;
    margin-top: 3px;
    border-color: #1454F2;

    &__input {
      margin-left: 8px;
    }

    &__tags {
      padding: 5px 10px;

      .el-tag.el-tag--info {
        background-color: #1454F2;
        border-color: #1454F2;
        color: #FFFFFF;
        border-radius: 50px;
        padding-left: 15px;
        padding-right: 30px;

        position: relative;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .el-tag.el-tag--info .el-tag__close {
        border: 1px #FFFFFF solid;
        color: #FFFFFF;
        background: #1454F2;

        position: absolute;
        right: 5px;
        top: 4px;
      }
    }

    .el-input__inner {
      border-radius: 50px;
      border: 2px solid #1454F2;

      &::placeholder {
        font-family: 'Raleway', sans-serif;
        font-size: 12px;
        color: #9D9D9D;
        padding-left: 16px;
      }
    }

    .el-input.is-focus .el-input__inner {
      border-color: #1454F2;
    }
  }

  &__select {
    .el-select-dropdown__item {
      font-family: Roboto, sans-serif;
      color: #121212;
      font-weight: 400;
    }

    .el-scrollbar__wrap {
      max-height: 300px;
    }

    .el-select-dropdown__empty {
      padding: 10px 0;
      margin: 0;
      text-align: center;
      color: #999;
      font-family: Raleway;
      font-size: 14px;
    }
  }

  &__select-header {
    display: flex;
    justify-content: space-between;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: #404040;
  }

  &__select-title {
    &--error {
      color: red;
    }
  }

  &__select-title-error {
    font-size: 10px;

    max-width: 250px;
    color: red;
  }

  .base-modal__header {
    &--title {
      margin-left: 0px !important;
    }
  }
  .base-modal__content {
    text-align: left;
  }
}

</style>