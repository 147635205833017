<template>
  <el-popover
      popper-class="app-popover"
      :disabled="disabled"
      placement="bottom"
      v-model="visible">
    <div class="app-popover__title">
      <slot name="title"></slot>
    </div>

    <div class="app-popover__actions">
      <div class="app-popover__action app-popover__action--bg--blue" @click="clickYesDelete">Да</div>
      <div class="app-popover__action" @click="visible = false">Отмена</div>
    </div>

    <slot name="reference" @click.stop="emitDeleteIfDisabled"></slot>
    <slot name="icon" slot="reference"></slot>
  </el-popover>
</template>

<script>
export default {
  name: "AppPopover",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    emitDeleteIfDisabled: function () {
      if (this.disabled) {
        this.$emit('click-yes')
      }
    },
    clickYesDelete() {
      this.$emit('click-yes')
      this.visible = false
    }
  }
}
</script>

<style lang="scss">
.app-popover__title {
  word-break: break-word;
}
</style>

<style lang="scss">
.el-popover.app-popover {
  max-width: 280px;
  width: max-content;
}
</style>