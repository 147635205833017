<template>
  <div class="text-lesson-wrapper" v-loading="loading">
    <div class="text-lesson__title-input">
      <div class="text-lesson__label">Название урока <span style="color: red">*</span></div>
      <el-input class="title-lesson-input" placeholder="Название урока" v-model="lesson.name" maxLength="130"/>
    </div>
    <div class="text-lesson__content-input">
      <div class="text-lesson__label">Текстовый контент <span style="color: red">*</span></div>
      <app-editor v-model="lesson.description"/>
    </div>


    <div class="text-lesson">
      <div class="text-lesson__item">
        <div class="text-lesson__label">Медиа вложения</div>
        <div v-for="(item) in lesson.attachments.filter(attach => attach.type !== 'video')" :key="item.id">
          <text-lesson-input-file :index="item._id ? item._id : item.id"
                                  :key="item.id"
                                  :keyItem="index"
                                  :file="item"
                                  @loadFile="loadFile"
                                  @deleteFile="deleteFile"/>
        </div>

        <div class="text-lesson__add-file" @click="clickAddFile">
          <img src="@/assets/img/courses/program/button/icon-add.svg" alt="add">
          <div class="text-lesson__add-title">Добавить файл</div>
        </div>
      </div>
    </div>


    <div class="text-lesson">
      <div class="text-lesson__item">
        <div class="text-lesson__label">Видео</div>
        <div v-for="(item) in lesson.attachments.filter(attach => attach.type === 'video')" :key="item.id || item._id">
          <text-lesson-input-file
              :index="item._id ? item._id : item.id"
              :key="item.id"
              :keyItem="index"
              :file="item"
              type="video"
              accept="video/mp4,video/x-m4v,video/*"
              @loadFile="loadFile"
              @deleteFile="deleteFile"
          />
        </div>

        <div class="text-lesson__add-file" @click="clickAddFile('video')">
          <img src="@/assets/img/courses/program/button/icon-add.svg" alt="add">
          <div class="text-lesson__add-title">Добавить файл</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TextLessonInputFile from "./TextLessonInputFile";
import AppEditor from "@/components/AppEditor.vue";

import {validationMixin} from "vuelidate";
import {required, minLength, maxLength} from 'vuelidate/lib/validators'
import {validate} from "../../../../../../../../utils/validators";
import {isEqual, cloneDeep} from "lodash"
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "TextLesson",
  mixins: [validationMixin],
  components: {
    TextLessonInputFile,
    AppEditor
  },
  props: {
    lesson: Object,
    index: Number,
    loading: Boolean,
    maxNumber: Number
  },
  data() {
    return {
      copyLesson: null,
      idDeletedFiles:[],
    }
  },
  computed: {
    isModifiedLesson() {
      return !isEqual(this.lesson,this.copyLesson) && validate(this.$v)
    },
    isValidQueueNumber() {
      return Number(this.copyLesson.queueNumber) !== this.lesson.queueNumber && this.copyLesson.queueNumber !== ''
          && this.copyLesson.queueNumber !== '0' && this.maxNumber >= this.copyLesson.queueNumber && this.lesson.id
    },
  },
  created() {
      if(!this.lesson.attachments)
        this.lesson.attachments = [{}]


    this.copyLesson = cloneDeep(this.lesson)

    this.$v.$reset()
  },
  watch:{
    lesson(){
      if(!this.lesson.attachments)
        this.lesson.attachments = [{}]

      this.copyLesson = cloneDeep(this.lesson)
    },
    'lesson.queueNumber'(){
      this.copyLesson = cloneDeep(this.lesson)
    }
  },
  methods: {
    clickAddFile(type) {
      if(!this.lesson.attachments)
        this.lesson.attachments = [{ _id: uuidv4(), type }]
      else {
        this.lesson.attachments.push({ _id: uuidv4(), type })
      }
    },
    clickSave() {
      if (this.isModifiedLesson) {
        if (this.lesson.id) {
          this.$emit('updateLesson', {lesson: this.lesson, idDeletedFiles: this.idDeletedFiles,index: this.index})
        } else {
          this.$emit('addLesson', {lesson: this.lesson, index: this.index})
        }
        this.copyLesson = cloneDeep(this.lesson)

        this.idDeletedFiles=[]
      }
    },
    loadFile(data) {
      if (typeof data.index === 'string') {
        const index = this.lesson.attachments.findIndex(item => item._id === data.index)
        if (index !== -1) {
          this.$set(this.lesson.attachments, index, {
            lessonId: this.lesson.id,
            file: data.file,
            _id: data.index,
            type: this.lesson.attachments[index].type,
            title: data.title
          })
        }
      } else {
        const index = this.lesson.attachments.findIndex(item => item.id === data.index)
        if (index !== -1) {
          this.$set(
              this.lesson.attachments,
              index, {
                id: this.lesson.attachments[index].id,
                lessonId: this.lesson.id,
                file: data.file,
                title: data.title
              })
        }
      }
    },
    deleteFile(index) {
      if (typeof index !== 'string') {
        this.idDeletedFiles.push(index)
        const indexAtt = this.lesson.attachments.findIndex(item => item.id === index)
        if (indexAtt !== -1) {
          this.lesson.attachments.splice(indexAtt, 1)
        }
      } else {
        const indexAtt = this.lesson.attachments.findIndex(item => item._id === index)
        if (indexAtt !== -1) {
          this.lesson.attachments.splice(indexAtt, 1)
        }
      }
    },

    updateQueueNumber(){
      if(this.isValidQueueNumber){
        this.$emit('updateQueueNumber',
            {number: this.lesson.queueNumber,
                  newNumber: Number(this.copyLesson.queueNumber),
                  id: this.lesson.id})
      }
      else {
        this.copyLesson.queueNumber = this.lesson.queueNumber
      }
    }
  },
  validations: {
    lesson: {
      name: {required, minLength: minLength(2), maxLength: maxLength(164)},
      description: {required, minLength: minLength(2)},
    }
  }
}
</script>

<style lang="scss">
.text-lesson-wrapper {
  display: flex;
  flex-direction: column;

  margin-top: 17px;

  .number-lesson{
    .el-input__inner{
      position: absolute;
      width: 35px;
      height: 35px;

      left: 85px;
      top: 7px;
      padding: 0 5px;

      font-family: Montserrat, sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #121212;

    }
  }

  .el-collapse{
    border:none;
  }
  .el-collapse-item__header{
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    padding: 0 15px;


    border-radius: 100px;
    border: 1px solid #DCDFE6;
  }

  .el-input.title-lesson-input {
    .el-input__inner {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #121212;
      height: 50px;

      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 100px;
    }
  }

  .el-textarea.content-lesson-input {
    .el-textarea__inner {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #121212;
      min-height: 180px !important;

      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 20px;
    }
  }

  .text-lesson {
    display: flex;
    align-items: flex-start;

    margin-top: 10px;

    &__label {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;

      margin-bottom: 10px;
    }

    &__item {
      display: flex;
      flex-direction: column;

      margin-left: 10px;
    }

    &__text-label {
      display: flex;
      align-items: center;

      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      cursor: pointer;

      &--disabled {
        cursor: not-allowed;
        color: #C0C4CC;
      }

      img {
        margin-right: 12px;
      }
    }

    &__title-input {
      margin-top: 24px
    }

    &__content-input {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__add-file {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 245px;
      height: 50px;

      background: #407BFF;
      border-radius: 120px;

      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        bottom: 50%;
        left: 0;
        width: 100%;
        height: 0;
        background: rgba(255, 255, 255, 0.1);
      }

      &:hover:before {
        transition: 400ms;
        bottom: 0;
        height: 100%;
      }
    }

    &__add-title {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;

      color: #FFFFFF;
      margin-left: 15px;
    }

    &__action-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__save-btn {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 222px;

      background: #4EE1A3;
      border-radius: 120px;

      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        bottom: 50%;
        left: 0;
        width: 100%;
        height: 0;
        background: rgba(255, 255, 255, 0.1);
      }

      &:hover:before {
        transition: 400ms;
        bottom: 0;
        height: 100%;
      }

      &--disabled {
        background: #C4C4C4;
        cursor: not-allowed;

      }
    }

    &__save-btn-title {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;

      color: #FFFFFF;
    }

    &__btn-type-title{
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: Montserrat, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;

      color: #121212;
      padding: 13px 20px;

      width: 233px;
      height: 50px;

      border: 2px solid #4EE1A3;
      box-sizing: border-box;
      border-radius: 120px;

      cursor: default;
    }
    &__btn-type-label{
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;

      margin-bottom: 10px;
    }

  }

}
</style>
