<template>
  <div class="base-modal">
    <el-dialog :width="width" :visible="visible" :before-close="handleClose" :close-on-click-modal="false">

      <template slot="title">
        <div class="base-modal__header">
          <img v-if="image" :src="image" alt="">
          <div class="base-modal__header--title">{{ title }}</div>
        </div>
      </template>

      <div v-loading="loading" class="base-modal__content">
        <form @submit.prevent="$emit('submit')">
          <slot></slot>

          <el-button :disabled="loading" native-type="submit"
                     class="base-modal__footer-btn" icon="el-icon-check"
                     type="success">
            Сохранить
          </el-button>

        </form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'base-modal',
  props: {
    title: String,
    image: String,
    visible: Boolean,
    loading: Boolean,

    width: { type: String, default: '420px' }
  },
  methods: {
    handleClose: function () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.base-modal {

  .base-modal__header {
    display: flex;
    align-items: center;

    padding-bottom: 10px;

    border-bottom: 1px solid #E7E7E7;

    &--title {
      margin-left: 20px;

      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;

      color: #000000;
    }

  }

  .base-modal__content {
    padding: 0 20px 10px 20px;
  }

  .base-modal__footer-btn {
    display: block;
    padding: 0;
    width: 194px;
    height: 32px;
    margin-top: 10px;
    border: none;

    background: #4EE1A3;
    border-radius: 2px;

    transition: 200ms;


    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #FFFFFF;


    &:hover {
      background: #70efba;
    }
  }

}
</style>

<style lang="scss">
.base-modal .el-dialog__body {
  padding: 0;
}

.base-modal .el-dialog__footer {
  padding-top: 0;
  text-align: left;
}

.base-modal .el-dialog__headerbtn {
  background: #FFFFFF;
  border: 0.5px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  padding: 4px;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in;

  i {
    height: 9px;
    width: 11px;
    transition: all .2s ease-in;
    color: #AAAAAA !important;
  }

  &:hover {
    background: #FF7262;
    border-color: #FF7262;

    i {
      color: white !important;
    }
  }
}

.base-modal__footer .el-loading-spinner {
  .circular {
    display: none;
  }
}
</style>
