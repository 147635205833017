<template>
  <div class="header-program-course">
    <div class="header-program-course__title">программа курса</div>
<!--    <div class="header-program-course__program-counts">-->
<!--      <div class="header-program-course__program-count">Уроков: {{ count }}</div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "HeaderProgramCourse",
  props: {
    // count: { type: Number, default: 0 }
  }
}
</script>

<style lang="scss" scoped>
.header-program-course {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  flex-wrap: wrap;

  width: 100%;

  margin-left: auto;
  margin-right: auto;

  margin-bottom: 54px;

  transition: 0.2s;

  @media screen and (max-width: 700px) {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 24px;
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 22px;
  }

  &__title {
    font-family: Gilroy, Raleway, sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 58px;
    text-align: left;
    margin-bottom: 32px;

    max-width: 360px;
    transition: 0.2s;


    @media screen and (max-width: 1200px) {
      font-size: 54px;
    }

    @media screen and (max-width: 980px) {
      font-size: 46px;
    }
    @media screen and (max-width: 700px) {
      font-size: 38px;
      line-height: 100%;
    }
    @media screen and (max-width: 600px) {
      font-size: 36px;
      margin-bottom: 24px;
    }
    @media screen and (max-width: 440px) {
      font-weight: 400;
      font-size: 34px;
      padding: 0 10px;
      margin-bottom: 22px;
    }
  }

  &__program-counts {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__program-count {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 153px;
    height: 47px;

    border: 1.4px solid #1454F2;
    border-radius: 1000px;
    box-sizing: border-box;

    font-family: Gilroy, Raleway, sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #222222;

    transition: 0.2s;

    &:first-child {
      margin-right: 8px;
    }

    @media screen and (max-width: 600px) {
      width: 126px;
      height: 42px;
      font-weight: 500;
      font-size: 16px;

      &:first-child {
        margin-right: 10px;
      }
    }

    @media screen and (max-width: 440px) {
      border: none;
      width: 136px;
      height: 28px;

      &:first-child {
        color: #1454F2;
        background: rgba(20, 84, 242, 0.1);
        margin-right: 4px;
      }
      &:last-child {
        color: #FF9F8C;
        background: rgba(255, 159, 140, 0.1);
      }
    }
  }
}
</style>
