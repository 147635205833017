<template>
  <div class="course-certificate">
    <div class="course-certificate__title">cертификат об окончании курса</div>
    <div class="course-certificate__info">
      <div v-if="certificateName" class="course-certificate__info">
        <i class="el-icon-circle-check"/>
        <span>Загружен сертификат “{{ certificateName }}”</span>
      </div>
      <div v-else class="course-certificate__info">
        <i class="el-icon-circle-close" style="color: red"/>
        <span>Ошибка! Сертификат не найден!</span>
      </div>
    </div>
  </div>
</template>

<script>
import {certificatesService} from "@/app/admin/certificates/certificates.service";

export default {
  name: "CourseCertificate",
  props: {
    certificateTemplateId: {type: Number, default: null}
  },
  computed: {
    certificateName() {
      if (this.certificateTemplateId) {
        const template = this.options.find(item => item.id === this.certificateTemplateId)
        if (template && template.name) {
          return template.name
        } else {
          return null
        }
      } else {
        return null
      }
    }
  },
  data() {
    return {
      options: []
    }
  },
  created() {
    certificatesService.getCertificates()
        .then(data => {
          this.options = data.map(item => ({
            id: item.id,
            name: item.name
          }))
        })
  },
  methods: {
    changeCertificate() {
      this.$emit('input', this.temp)
    }
  }
}
</script>

<style scoped lang="scss">
.course-certificate {
  padding-bottom: 100px;

  &__title {
    font-family: Gilroy, Raleway, sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 58px;
    text-align: center;
    transition: 0.2s;

    margin-bottom: 64px;

    @media screen and (max-width: 1200px) {
      font-size: 54px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin: 0 auto;


    i {
      margin-right: 10px;
      font-size: 32px;
      color: #4EE1A3;
    }

    span {
      font-family: Raleway, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;

      color: #000000;
    }

  }

}
</style>
