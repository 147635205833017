import Vue from 'vue'
import Main from "./ProgressNotification.vue";

const ProgressNotificationConstructor = Vue.extend(Main)
let instance = new ProgressNotificationConstructor()
instance.$mount()

const ProgressNotification = {

    add(progressFile) {

        instance.addFile(progressFile)
        instance.visible = true
        document.body.appendChild(instance.$el)
    },
    close(){
        instance.close()
    }
}



export default ProgressNotification

