<template>
<div class="course-control__wrapper">
  <div class="course-control__title">
    Курс загружается
    <div> . . .</div>
  </div>
  <div class="course-control__btn">Редактировать</div>
</div>
</template>

<script>
export default {
  name: "CourseControl"
}
</script>

<style lang="scss" scoped>
.course-control {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 47px;
    margin-bottom: 49px;
  }
  &__title {
    display: flex;
    font-family: Raleway, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: #121212;

    div{
      margin-left: 8px;
      animation: ellipse1 1s infinite;
      overflow: hidden;
      white-space: nowrap;
      @keyframes ellipse1 {
        0% {
          width: 0;
        }
        50% {
          width: 15px;
        }
        100% {
          width: 30px;
        }
      }
    }
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #FFFFFF;

    height: 54px;
    width: 240px;

    background: #E8E8E8;
    border-radius: 120px;

  }
}
</style>