<template>
  <div class="admin-courses">
    <div class="admin-courses__header ">
      <div class="admin-courses__title">Курсы</div>
      <add-button @click.native="openAddCourseModal">Добавить курс</add-button>
    </div>

    <transition-group appear class="admin-courses__buttons"
                      :css="false"
                      @enter="handleEnter">
      <course-item class="admin-courses__button"
                   v-for="(item,index) in filteredCourses"
                   :key="item.id"
                   :data-index="index"
                   @click="selectCourseId(item.id)"
                   @delete-course="deleteCourse(item.id)"
                   :course="item"/>
    </transition-group>

    <add-course-modal ref="addCourseModal"
                      :loading="loadingCourse"
                      @add-course="addCourse">
    </add-course-modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

import AddButton from "../../../components/AddButton";
import CourseItem from "./CourseItem";

import {courseService} from "../../course.service";
import AddCourseModal from "./modals/AddCourseModal";

export default {
  name: "Courses",
  components: {
    AddCourseModal,
    AddButton,
    CourseItem
  },
  computed: {
    ...mapGetters(['courses', 'categories', 'selectedCategory','selectedCourseId']),

    filteredCourses() {
      if (!this.selectedCategory) return []

      return this.courses.filter(course => course.categories.includes(this.selectedCategory.id))
    }
  },
  mounted() {
    if (this.filteredCourses.length)
      this.selectCourseId(this.filteredCourses[0].id)
  },
  data() {
    return {
      loadingCourse: false,
    }
  },
  methods: {
    ...mapMutations(['setCourses', 'selectCategory', 'selectCourseId', 'deleteCourseById']),

    handleEnter(el){
      el.style.opacity  = 0
      el.style.cursor  = 'default'
      el.style.pointerEvents  = 'none'

      setTimeout(()=>{
        el.style.opacity  = 1
        el.style.cursor  = 'pointer'
        el.style.pointerEvents  = 'auto'
      }, (el.dataset.index+1) * 10)
    },
    deleteCourse: function (courseId) {
      this.deleteCourseById(courseId)
      if (this.filteredCourses.length) this.selectCourseId(this.filteredCourses[0].id)
      courseService.deleteCourse(courseId)
    },

    addCourse: async function (course) {
      this.loadingCourse = true

      if (course.categories) {
        const firstCategory = course.categories[0]
        this.selectCategory(this.categories.find(category => category.id === firstCategory))
      }

      const newCourse = await courseService.addCourse(course)
      this.courses.push(newCourse)

      this.selectCourseId(newCourse.id)

      this.loadingCourse = false
      this.$refs.addCourseModal.closeModal()
    },

    openAddCourseModal: function () {
      this.$refs.addCourseModal.openModal(this.courses)
    }
  },
}
</script>

<style scoped lang="scss">
.admin-courses {
  &__header {
    display: flex;
    flex-direction: column;

    margin-bottom: 30px;
  }

  &__title {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #2A2A2A;

    margin-bottom: 14px;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;

    margin-top: 32px;
  }

  &__button {
    margin-right: 15px;
    margin-bottom: 24px;

    &:active {
    }
  }
}
</style>