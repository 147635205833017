<template>
  <div>
    <el-dialog
        :visible="visible"
        width="500px"
        :before-close="handleClose"
        class="buy-course-popup">
      <template slot="title">
        <div class="buy-course-popup__header">
          <img id="buy-course-popup-icon" src="@/assets/img/user/icon-wallet.svg" alt="icon">
          <div class="buy-course-popup__title">Курс</div>
        </div>
        <el-divider/>
      </template>
      <div class="buy-course-popup__content" v-loading="loading">
        <div class="buy-course-popup__name-course">{{ courseInfo.name }}</div>
<!--        <div class="buy-course-popup__price">-->
<!--          <span>Сумма к оплате</span>-->
<!--          {{ courseInfo.price }} &#8381;-->
<!--        </div>-->
        <template v-if="!userIsLoggedIn || isAdmin">
          <form @submit.prevent = buyCourse>
          <div class="buy-course-popup__description">
            <span>Введите e-mail:</span>
            После оплаты Вы получите доступ к курсу
            на указанный e-mail
          </div>
          <form-input v-model="email"
                      :validator="$v.email"
                      type="email"
                      placeholder="Введите e-mail"/>
          </form>
        </template>
        <div v-else class="buy-course-popup__description">
          После оплаты курса, он появится в личном кабинете
          в разделе “Мои курсы”
        </div>
      </div>
      <div class="buy-course-popup__btn-payment" @click="buyCourse">Оплатить</div>
    </el-dialog>
  </div>
</template>

<script>

import {myCoursesService} from "@/app/user/myCourses/myCourses.service";
import {coursesService} from "../../courses/courses.service";
import imgToSvg from "../../../../assets/js/imgToSvg";
import FormInput from "../../components/FormInput";
import {validationMixin} from "vuelidate";
import {email,required} from 'vuelidate/lib/validators'
import {validate} from "../../../../utils/validators";

export default {
  name: "BuyCoursePopup",
  mixins: [validationMixin],
  components: {FormInput},
  props: {
    courseInfo: Object
  },
  data() {
    return {
      loading: false,
      email: null
    }
  },
  computed: {
    userIsLoggedIn() {
      return this.$store.state.user.isLoggedIn
    },
    isAdmin() {
      return this.$route.name === 'admin-courses'
    },
    visible() {
      return this.$store.state.myCourses.showBuyCoursePopup
    },
    courseId() {
      return this.$route.params.courseId
    }
  },
  // watch: {
  //   visible(val) {
  //     if (val && !this.isLoggedIn) {
  //       this.handleClose()
  //       this.$router.push('/login')
  //     }
  //   }
  // },
  mounted() {
    imgToSvg('#buy-course-popup-icon')
  },
  methods: {
    buyCourse() {
      if (this.isLoggedIn) {
        this.loading = true
        myCoursesService.buyCourse(this.courseId)
            .then(() => this.handleClose())
            .finally(() => this.loading = false)
      } else {
        if(validate(this.$v)) {
          this.loading = true
          coursesService.buyСourseWithoutAuthorization(this.courseId, this.email)
              .then(() => this.handleClose())
              .finally(() => this.loading = false)
        }
      }
    },
    handleClose() {
      this.$router.replace({'query': null});

      this.$store.commit('myCourses.setShowBuyCoursePopup', false)
      this.email = ''
      this.$v.$reset()
    }
  },
  validations: {
    email: {email: email, required},
  }

}
</script>

<style lang="scss">
.buy-course-popup {

  .el-dialog {
    max-width: calc(100% - 40px);
    border-radius: 16px;
  }

  .el-dialog__header {
    padding: 16px;

    .el-divider {
      margin-top: 7px;
      margin-bottom: 0;

      background: #E7E7E7;
    }

    .el-dialog__headerbtn {
      top: 14px;
      height: 24px;

      &:after {
        position: absolute;
        top: -20px;
        right: -20px;
        width: 0;
        height: 0;
        content: '';

        transition: 0.1s ease-in;
        background: rgba(36, 131, 213, 0.08);
        border-bottom-left-radius: 70%;
      }

      &:hover, &:focus {
        i {
          color: rgba(36, 131, 213, 0.76);
        }

        &:after {
          width: 52px;
          height: 52px;
        }
      }
    }
  }

  .el-dialog__body {
    padding: 8px 16px 16px 16px;
  }

  .form-input__error-text {
    font-size: 11px;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;

    margin-left: 10px;
  }

  &__name-course {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #000000;

    word-break: break-word;

    padding-right: 20px;
    margin-bottom: 16px;
  }

  &__price {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1454F2;

    margin-bottom: 32px;

    span {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      margin-bottom: 4px;
    }
  }

  &__description {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #1454F2;
    max-width: 310px;
    word-break: break-word;

    margin-bottom: 16px;

    span {
      display: block;
      color: #000000;
      margin-bottom: 4px;
    }
  }

  &__info-aveabled{
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    word-break: break-word;
    text-align: left;
    color: black;
    margin-bottom: 8px;
  }

  &__btn-payment {
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;

    height: 52px;

    max-width: 224px;
    margin: auto;
    margin-top: 24px;

    background: #407BFF;
    border-radius: 100px;

    cursor: pointer;

    transition: 0.2s;

    &:hover {
      background: #4072ef;
    }
  }
}
</style>
