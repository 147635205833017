<template>
  <div class="course-study-info" v-if="options.length">
    <div class="course-study-info__title">благодаря курсу вы</div>
    <div class="course-study-info__options">

      <div class="course-study-info__option" v-for="(option, index) in options" :key="index">
        <div class="course-study-info__option-number">0{{ index + 1 }}</div>
        <div class="course-study-info__option-title">{{ option.author }}</div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "CourseStudyInfo",
  props: {
    learningTopics: {
      type: Array
    },
  },
  computed: {
    options() {
      return this.learningTopics.filter(item => item.author || item.text)
    }
  }
}
</script>

<style lang="scss">
.course-study-info {

  margin-bottom: 140px;

  @media screen and (max-width: 1200px) {
    margin-bottom: 120px;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 100px;
  }
  @media screen and (max-width: 740px) {
    margin-bottom: 80px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 70px;
  }

  &__title {
    font-family: Gilroy, Raleway, sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-align: left;

    margin-bottom: 70px;
    transition: 0.2s;

    @media screen and (max-width: 1200px) {
      font-size: 54px;
      margin-bottom: 52px;
    }

    @media screen and (max-width: 980px) {
      font-size: 46px;
      margin-bottom: 48px;
    }
    @media screen and (max-width: 600px) {
      font-weight: 500;
      font-size: 36px;
      margin-bottom: 34px;
    }
    @media screen and (max-width: 360px) {
      font-size: 34px;
      font-weight: 400;
      margin-bottom: 26px;
      padding: 0 10px;
    }
  }

  &__options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px 30px;

    margin-right: auto;
    margin-left: auto;

    @media screen and (max-width: 980px) {
      gap: 16px 24px;
    }

    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
      gap: 16px;
    }

    @media screen and (max-width: 600px) {
      gap: 14px;
    }
  }

  &__option {
    padding: 30px;
    background: #FFF6F4;
    border-radius: 26px;

    transition: 0.2s;

    @media screen and (max-width: 600px) {
      padding: 20px 30px 30px 30px;
      border-radius: 22px;
    }

    @media screen and (max-width: 360px) {
      padding: 16px 20px 20px 20px;
      border-radius: 18px;
    }
  }

  &__option-number {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 60px;

    color: #222222;
    margin-bottom: 14px;

    @media screen and (max-width: 980px) {
      font-size: 38px;
    }

    @media screen and (max-width: 600px) {
      font-size: 34px;
      line-height: 51px;
      margin-bottom: 10px;
    }
  }


  &__option-title {
    font-family: 'Gilroy', Montserrat, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    white-space: pre-wrap;
    text-align: left;
    color: #222222;

    @media screen and (max-width: 980px) {
      font-size: 20px;
    }

    @media screen and (max-width: 600px) {
      font-weight: 500;
    }
    @media screen and (max-width: 360px) {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
    }
  }
}

</style>
