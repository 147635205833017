<template>
  <div class="form-select">
    <div class="form-select__title-wrapper">
      <div class="select-title"
           :class="{'form-select__select-title--error':  isError}">{{label}}
      </div>
      <div v-show="isError" class="form-select__select-title-error">{{validationErrorMessage}}</div>
    </div>
    <el-select v-model="selectedValue"
               :placeholder="placeholder"
               @change="$emit('input', $event)"
               popper-class="add-direction-wrapper__select"
               :multiple="multiple"
               filterable
               default-first-option>
      <el-option
          v-for="item in options"
          :disabled="includesDisableOptions(item.id)"
          :key="item.name"
          :label="item.name"
          :value="item.id">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import {validationMap} from "../utils/validators";

export default {
  name: "FormSelect",
  props: {
    multiple: { type: Boolean, default: true },
    placeholder: {type: String, default: ''},
    label: {type: String, default: ''},
    value: {type: [Array, Number]},
    validator: {type: Object},
    options: {type: Array},
    disableOptions: {type: Array, default: null}
  },
  computed: {
    isError() {
      return this.validator && this.validator.$error
    },
    validationErrorMessage() {
      for (const validationItem in this.validator) {
        if (Object.prototype.hasOwnProperty.call(this.validator, validationItem) && validationItem[0] !== '$') {
          if (this.validator[validationItem] === false) return validationMap[validationItem](this.validator)
        }
      }

      return ''
    }
  },
  created() {
    this.selectedValue = this.value
  },
  data() {
    return {
      selectedValue: null,
      observe:null
    }
  },
  mounted() {
    this.observe = new ResizeObserver(entries => {
      for (let entry of entries) {
        if(entry.target.className === 'el-select__tags') {
          const wrapperSelect = document.querySelector('.form-select .el-select .el-input__inner')
          if (entry.contentRect.height > 55)
            wrapperSelect.style.borderRadius = '25px'
           else
            wrapperSelect.style.borderRadius = '50px'
        }
      }
    });

    this.observe.observe(document.querySelector('.form-select  .el-select__tags'));
  },
  watch: {
    value() {
      this.selectedValue = this.value
    }
  },
  methods:{
    includesDisableOptions(id) {
      if(!this.disableOptions)
        return false

      let disabled = false

      this.disableOptions.forEach(item=>{
        if(item.id === id)
          disabled = true
      })

      return disabled
    },
  }
}
</script>

<style lang="scss">
.form-select {
  .el-select {
    width: 100%;
    margin-top: 7px;
    border-color: #1454F2;

    &__input {
      margin-left: 8px;
    }

    &__tags {
      padding: 5px 10px;

      .el-tag.el-tag--info {
        background-color: #1454F2;
        border-color: #1454F2;
        color: #FFFFFF;
        border-radius: 50px;
        padding-left: 15px;
        padding-right: 30px;

        position: relative;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .el-tag.el-tag--info .el-tag__close {
        border: 1px #FFFFFF solid;
        color: #FFFFFF;
        background: #1454F2;

        position: absolute;
        right: 5px;
        top: 4px;
      }
    }

    .el-input__inner {
      border-radius: 50px;
      border: 2px solid #1454F2;
      transition: all 0.1s;
      &::placeholder {
        font-family: 'Raleway', sans-serif;
        font-size: 12px;
        color: #9D9D9D;
      }
    }

    .el-input.is-focus .el-input__inner {
      border-color: #1454F2;
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: Roboto ,sans-serif;
    font-size: 14px;
    color: #404040;
    text-align: left;
  }

  &__select {
    .el-select-dropdown__item {
      font-family: Roboto, sans-serif;
      color: #121212;
      font-weight: 400;
    }

    .el-select-dropdown__item.is-disabled {
      color: #C0C4CC;
      cursor: not-allowed;
    }

    .el-scrollbar__wrap {
      max-height: 300px;
    }

    .el-select-dropdown__empty {
      padding: 10px 0;
      margin: 0;
      text-align: center;
      color: #999;
      font-family: Raleway;
      font-size: 14px;
    }
  }

  &__select-header {
    display: flex;
    justify-content: space-between;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: #404040;
  }

  &__select-title {
    &--error {
      color: red;
    }
  }

  &__select-title-error {
    font-size: 10px;

    max-width: 250px;
    color: red;
  }
}
</style>
