import http from "@/app/admin/config/admin-axios.config";

class CertificatesRepository {
    
    async getCertificates() {
        const response = await http.get(`/certificates`, {
            params: { sort_by_id: 'ASC' }
        })
        return response.data
    }

    async createCertificate(formData) {
        const response = await http.put(`/certificates`, formData)
        return response.data
    }
    
    async updateCertificate(certificateId, formData) {
        const response = await http.patch(`/certificates/${certificateId}`, formData)
        return response.data
    }
    
    async deleteCertificate(certificateId) {
        const response = await http.delete(`/certificates/${certificateId}`)
        return response.data
    }
    
    async makeTestPdf(certificateId, data) {
        const response = await http.post(`/certificates/${certificateId}/make`, {
            username: data.name,
            courseName: data.courseName,
            drawBorder: data.drawBorder
        }, { responseType: 'blob' })
        return response.data
    }
    
    async getDefaultFonts() {
        const response = await http.get(`/certificates/default_fontnames`)
        return response.data
    }

}

export const certificatesRepository = new CertificatesRepository()
