<template>
  <div v-if="loading">
    <categories></categories>
    <courses></courses>
    <course-without-categories></course-without-categories>
  </div>
  <base-preloader :height="'500px'" v-else/>

</template>

<script>
import Categories from "./Categories";
import Courses from "./Courses";
import CourseWithoutCategories from "./CourseWithoutCategories";
import BasePreloader from "../../../../../components/BasePreloader";

import {courseService} from "../../course.service";

export default {
  name: "CategoriesAndCourses",
  components: {
    CourseWithoutCategories,
    Courses,
    Categories,
    BasePreloader
  },
  data() {
    return {
      loading: false
    }
  },
  async created() {
    await courseService.getCategories()
    await courseService.getCourses()

    this.loading = true
  }
}
</script>

<style scoped>

</style>