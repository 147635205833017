<template>
  <div class="theme"
       v-loading="loading">
    <div class="theme__title">
      <el-input class="title-lesson-input" placeholder="Название темы" v-model="theme.name" maxLength="160"/>
      <el-tooltip
          effect="dark" placement="top"
          content="Обновить наименование темы"
      >
        <el-button
            type="success" icon="el-icon-check"
            @click="handleClickUpdateTheme"
        />
      </el-tooltip>
    </div>
    <div class="theme__content"
         :key="refresh"
         ref="content">
      <lesson
          class="theme__sort-item"
          v-for="item in theme.lessons" :key="item.id"
          :lesson="item"
          :theme-id="theme.id"
          @save="$emit('save-lesson', item)"
          @deleteLesson="handleDeleteLesson"
          ref="lesson"
      />
    </div>
    <div class="theme__footer">
      <add-lesson-btn
          label="Добавить урок"
          @click.native="handleClickAddLesson"
      />
      <delete-lesson-btn
          title="Вы уверены, что хотите удалить тему? Все уроки из данной темы так же будут удалены."
          button-text="Удалить тему"
          @deleteLesson="handleDeleteTheme"
      />
    </div>
  </div>
</template>

<script>
import AddLessonBtn from "./AddLessonBtn";
import DeleteLessonBtn from "@/app/admin/course/components/courseEditor/components/CourseProgramEditor/components/DeleteLessonBtn.vue";
import Lesson from "@/app/admin/course/components/courseEditor/components/CourseProgramEditor/components/Lesson.vue";
import {courseService} from "@/app/admin/course/course.service";
import Sortable from "sortablejs";

export default {
  name: 'theme',
  components: {
    AddLessonBtn,
    DeleteLessonBtn,
    Lesson
  },
  props: {
    theme: { type: Object },
    course: { type: Object }
  },
  data() {
    return {
      loading: false,
      refresh: 0
    }
  },
  mounted() {
    this.initDraggable()
  },
  methods: {
    initDraggable() {
      Sortable.create(this.$refs.content, {
        draggable: `.theme__sort-item`,
        animation: 150,
        onEnd: ($event) => this.handleDrop($event),
        onStart: () => this.$refs.lesson
            .forEach(lesson => lesson.$refs.collapse.setActiveNames(null))
      })
    },
    handleDrop({ newIndex, oldIndex }) {
      this.loading = true
      const ids = this.theme.lessons.map(item => item.id)
      const current = ids.splice(oldIndex, 1)[0]
      ids.splice(newIndex, 0, current)
      courseService.updatePositionsForTheme(this.theme.id, ids)
          .then(() => {
            ids.forEach((id, index) => {
              this.theme.lessons.find(item => item.id === id).position = index + 1
            })
            this.$emit('change-position')
          })
          .finally(() => this.loading = false)
    },
    handleClickAddLesson() {
      this.$emit('add-lesson')
    },
    handleClickUpdateTheme() {
      this.loading = true
      courseService.updateTheme(this.course.id, this.theme)
          .finally(() => this.loading = false)
    },
    handleDeleteLesson(lessonId) {
      if (typeof lessonId === 'string') {
        this.$emit('delete-lesson', this.theme.id, lessonId)
        return
      }
      this.loading = true
      courseService.deleteLesson(this.theme.id, lessonId)
          .then(() => this.$emit('delete-lesson', this.theme.id, lessonId))
          .finally(() => this.loading = false)
    },
    handleDeleteTheme() {
      this.loading = true
      courseService.deleteTheme(this.course.id, this.theme.id)
          .then(() => this.$emit('delete-theme', this.theme.id))
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style lang="scss" scoped>
.theme {
  margin-top: 20px;
  padding: 30px;
  border: 1px solid #DCDFE6;
  border-radius: 10px;
  &__title {
    display: flex;
    gap: 20px;
  }
  &__footer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
