<template>
  <transition name="el-notification-fade">
    <div class="progress-notification el-notification right"
         :class="{'progress-notification__to-right': toRight}"
         v-show="visible">
      <h2 class="progress-notification__title">Прогресс загрузки</h2>

      <div class="progress-notification__items">
        <div class="progress-notification__item" v-for="(uploadedFile, i) in uploadedFiles" :key="i"
             :class="{'progress-notification__item--loaded': uploadedFile.progress >= 100}">

          <span class="progress-notification__item-name">{{ uploadedFile.fileName }}</span>

          <transition appear name="el-fade-in" mode="out-in" :duration="100">
            <el-progress class="progress-notification__item-progress"
                         v-if="uploadedFile.progress !== null && uploadedFile.progress < 100"
                         :percentage="uploadedFile.progress"
                         :color="progressColors">
            </el-progress>
            <span v-if="uploadedFile.progress === null"
                  class="progress-notification__item-progress--error">
                  Отменено
            </span>
          </transition>

          <i class="el-icon-success progress-notification__success-icon" v-if="uploadedFile.progress > 99"/>
        </div>
      </div>

      <transition appear name="el-fade-in" mode="out-in" :duration="200">
        <i v-if="isAllFilesDownload" class="el-icon-minus el-notification__closeBtn" @click="toRight=true"></i>
        <i v-else class="el-icon-close el-notification__closeBtn" @click="close"></i>
      </transition>

      <div v-if="toRight" class="progress-notification__right" @click="toRight=false">
        <i class="el-icon-d-arrow-left"></i>
      </div>
    </div>
  </transition>
</template>

<script>
import {Progress as ElProgress} from 'element-ui'

export default {
  components: {
    ElProgress
  },
  data() {
    return {
      visible: false,
      closed: false,
      toRight: false,
      uploadedFiles: [],

      progressColors: [
        {color: '#f56c6c', percentage: 20},
        {color: '#e6a23c', percentage: 40},
        {color: '#5cb87a', percentage: 60},
        {color: '#1989fa', percentage: 80},
        {color: '#6f7ad3', percentage: 100}
      ]
    }
  },
  computed: {
    isAllFilesDownload() {
      return this.uploadedFiles.find(item => item.progress >= 0 && item.progress < 99 && item.progress !== null)
    }
  },
  methods: {
    destroyElement() {
      this.$el.removeEventListener('transitionend', this.destroyElement);
      // this.$destroy();
      this.uploadedFiles = []
      this.$el.parentNode.removeChild(this.$el);
    },
    addFile(file) {
      this.uploadedFiles.push(file)
      this.toRight = false
    },
    close() {
      this.visible = false
      this.closed = false
      this.$el.addEventListener('transitionend', this.destroyElement);
    }
  }
}
</script>

<style scoped lang="scss">
.progress-notification {
  flex-direction: column;
  padding-right: 10px;
  z-index: 2001;
  top: 110px;
  overflow: visible;

  .el-icon-success {
    margin-left: 8px;
  }

  .el-progress {
    margin-left: 6px;
    min-width: 150px;
  }

  &__to-right {
    right: -331px !important;
  }

  &__right {
    position: absolute;
    left: -22px;
    bottom: calc(50% - 35px);
    display: flex;
    align-items: center;
    justify-content: center;

    height: 70px;
    width: 20px;

    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-left: 0.5px solid gainsboro;
    background: #FFFFFF;
  }

  &__close-btn {
    position: absolute;
    top: 18px;
    right: 15px;
    color: #909399;
    font-size: 16px;
    cursor: pointer;
  }

  &__title {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    text-align: left;
    margin: 0;
    font-size: 16px;
  }

  &__items {
    margin-top: 6px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    &-name {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-progress {
      flex: 1;

      &--error {
        font-family: Montserrat, sans-serif;
        font-size: 13px;
        color: #e80a0a;
        margin-left: 10px;
      }
    }

    &--loaded {
      .progress-notification__item-name {
        width: max-content;
      }
    }
  }

  &__success-icon {
    font-size: 20px;
  }
}
</style>