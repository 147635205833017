<template>
  <div class="admin-course-wrapper">
    <div class="admin-course__header">
      <div class="admin-course__header-title">Курс "{{ course.name }}"</div>
      <div class="admin-course__edit-btn"
           :class="{'admin-course__edit-btn--green': isEditing}"
           @click="changeHandler">
        {{ btnText }}
        <i class="el-icon-loading" style="font-size: 20px" v-if="loading"></i>
      </div>
    </div>
    <div class="admin-course__switches" key="statusCourse">
      <div>
        <el-switch
            v-model="course.isPublic"
            @change="updatePublicStatus"
            active-color="#13ce66"
            inactive-color="#ff4949">
        </el-switch>

        <transition appear name="el-fade-in" :duration="80" mode="out-in">
          <span :key="coursePublicStatus">{{ coursePublicStatus }}</span>
        </transition>
      </div>
    </div>

  </div>

</template>

<script>
import {courseService} from "../course.service";
import {mapGetters} from "vuex";

export default {
  name: "CourseControl",
  props: {
    isEditing: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    course: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['categories', 'courses']),
    btnText() {
      if (this.loading) {
        return 'Сохранение курса...'
      } else {
        return this.isEditing ? 'Сохранить курс' : 'Редактировать курс'
      }
    },
    coursePublicStatus(){
      return this.course.isPublic ? 'Публичный курс' : 'Не публичный курс'
    },
    coursesFiltered() {
      return this.courses
          .filter(item => item.categories
              .find(catId => this.course.categories?.find(cat => cat === catId))
          )
          .filter(item => item.id !== this.course.id)
    },
    isNameValid() {
      return !this.coursesFiltered.find(item => item.name === this.course.name)
    }
  },
  methods: {
    changeHandler: function () {
      if (this.isEditing && !this.isNameValid) {
        this.$message.error({ message: 'Курс с таким именем уже существует' })
        return
      }
      if (this.loading) return
      this.$emit(this.isEditing ? 'save' : 'edit')
    },
    updatePublicStatus(){
      courseService.updatePublicStatus(this.course).then(()=>{
        this.$notify.success({
          title: 'Успех',
          message: 'Статус обновлен'
        })
      })
    },
  }
}
</script>

<style scoped>

</style>