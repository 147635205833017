<template>
  <div class="input-wrapper"
       :class="{'input-wrapper__logo': mode==='logo'}"
       :style="{'height': preview?'max-content':'100%'}">
    <div class="input-wrapper__add"
         @click="handleClick">
      <input class="input-wrapper__input-add" name="input-add" type="file" ref="input"  accept="image/png, image/jpeg"
             @change="handleFiles">
      <div v-if="preview" style="display: flex; height: 100%">
        <template v-if="mode==='logo'">
          <el-image class="input-wrapper__icon-logo"
                    fit="cover" :src="preview"
                    @click.stop="handleClick">
            <div slot="error" class="input-wrapper__icon-logo">
              <div class="input-wrapper__icon-add">
                <img src="../../../../assets/img/admin/add-icon.svg">
                <div>Добавить фото</div>
              </div>
            </div>
          </el-image>
        </template>
        <el-image v-if="mode==='author'" class="input-wrapper__icon-author"
                  fit="cover" :src="preview"
                  @click.stop="handleClick">
          <div slot="error" class="input-wrapper__icon-author">
            <div class="input-wrapper__icon-add">
              <img src="../../../../assets/img/admin/add-icon.svg">
              <div>Добавить фото</div>
            </div>
          </div>
        </el-image>
        <el-image v-if="mode==='student'" class="input-wrapper__icon-student"
                  fit="cover" :src="preview"
                  @click.stop="handleClick">
          <div slot="error" class="input-wrapper__icon-student">
            <div class="input-wrapper__icon-add">
              <img src="../../../../assets/img/admin/add-icon.svg">
            </div>
          </div>
        </el-image>
<!--        <img v-if="mode==='logo'" class="input-wrapper__icon-logo" :src="preview" alt="" @click.stop="handleClick">-->
<!--        <img v-if="mode==='author'" class="input-wrapper__icon-author" :src="preview" alt="" @click.stop="handleClick">-->
<!--        <img v-if="mode==='student'" class="input-wrapper__icon-student" :src="preview" alt=""-->
<!--             @click.stop="handleClick">-->
      </div>
      <div v-else class="input-wrapper__icon-add">
        <img src="../../../../assets/img/admin/add-icon.svg">
        <div>Добавить фото</div>
      </div>
    </div>
  </div>
</template>

<script>

import {notificationsHelper} from "@/helpers/notifications.helper";
export default {
  name: "CourseImageLoader",
  props: {
    value: [File, Blob, String],
    mode: String
  },
  data() {
    return {
      preview: null,
      flagDel: true
    }
  },
  mounted() {
    if (this.value) {
      if (typeof this.value === 'string') {
        this.preview = this.value
      } else {
        this.preview = URL.createObjectURL(this.value)
      }
    }
  },
  methods: {
    handleClick: function () {
      this.$refs.input.click()
    },

    handleFiles: function () {
      if(this.isValidFile(this.$refs.input.files[0])) {
        this.preview = window.URL.createObjectURL(this.$refs.input.files[0])
        this.$emit('input', this.$refs.input.files[0])
      }
    },

    deletePhoto: function () {
      this.flagDel = false
      this.$emit('input', null)
      this.$refs.input.value = ""
      this.preview = null
    },

    isValidFile(file) {
      if(!file)
        return false

      const typeFile = file.name.split('.').pop()

      if (!["jpeg", "jpg", "png", "jfif"].includes(typeFile)) {
        notificationsHelper.warning('Неверный тип файла')
        return false
      }
      return true
    }
  },
  watch: {
    value() {
      if (this.value) {
        if (typeof this.value === 'string') {
          this.preview = this.value
        } else {
          this.preview = URL.createObjectURL(this.value)
        }
      } else {
        this.preview = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  width: 100%;
  height: 100%;

  &__logo{
    min-height: 205px;
    padding-top: 23px;

    .input-wrapper__add {
      min-height: 204px;
    }
  }

  &__icon-add {
    text-align: center;

    div {
      font-family: Roboto, sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 12px;
      color: #525252;
    }
  }

  &__input-add {
    display: none;
  }

  &__add {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    position: relative;
    overflow: hidden;

    background: #FFFFFF;
    border: 0.5px solid #D7D7D7;
    box-sizing: border-box;
    border-radius: 4px;

    cursor: pointer;

    &:focus {
      border-color: #7B8DFF;
    }
  }

  &__icon-logo {
    width: 100%;
    max-height: 300px;
    border-radius: 2px;

    cursor: pointer;
  }
  &__icon-logo-remove {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 5px;
    right: 5px;

    width: 32px;
    height: 32px;

    background: #FF5151;
    border-radius: 100%;

    cursor: pointer;
  }
  &__icon-student {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
  }

  &__icon-author {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    width: 390px;
    height: 480px;
    border-radius: 26px;
  }

  &__add-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
<style lang="scss">
.input-wrapper {
  width: 100%;
  height: 100%;

  &__icon-logo {
    .el-image__inner{
      max-height: 300px;
    }
  }
}
</style>
