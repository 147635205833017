import {notificationsHelper} from "../../../helpers/notifications.helper";
import {courseRepository} from "./course.repository";
import cancelableWrapper from "../../../utils/cancelableWrapper";
import checkIsFile from "./utils/checkIsFile";
import {objectCamelToUnderscore} from "../../../utils/HTTPDataParser";
import store from "@/store";
import axios from "axios";

class CourseService {
    _getCoursePromise = null
    cancelToken = axios.CancelToken.source();
    downloadLesson = []
    downloadVideoIntroLesson = []

    async getCategories() {
        try {
            const categories = await courseRepository.getCategories()
            store.commit('setCategories', categories)

            return categories
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            throw error
        }
    }

    async addCategory(category) {
        try {
            return await courseRepository.addCategory(category)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: 'Данное направление существует', backend: false})
            return Promise.reject(error)
        }
    }

    async updateCategory(category) {
        try {
            const data = await courseRepository.updateCategory(category)
            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.reject(error)
        }
    }

    async deleteCategory(categoryId) {
        try {
            const data = await courseRepository.deleteCategory(categoryId)
            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.reject(error)
        }
    }

    async getCourses() {
        try {
            const courses = await courseRepository.getCourses()
            store.commit('setCourses', courses)

            return courses
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.reject(error)
        }
    }

    async getCourseById(courseId) {
        try {
            if (this._getCoursePromise) this._getCoursePromise.cancel()

            this._getCoursePromise = cancelableWrapper(courseRepository.getCourseById(courseId))

            let data = this._getCoursePromise

            let themes = this.getThemes(courseId)

            // let lessons = this.getLessons(courseId)

            let certificatesPhoto = this.getAuthorCertificatesById(courseId)

            let videoIntro = this.getLessonIntro(courseId)

            const responses = await Promise.all([data, themes, certificatesPhoto,videoIntro])

            data = responses[0]
            // lessons = responses[1]
            themes = responses[1].map(item => ({
                ...item,
                lessons: item.lessons || []
            }))
            certificatesPhoto = responses[2]

            let reviews = data.reviews ? data.reviews : []
            reviews = reviews.map(item => ({
                ...item,
                picture: `${store.state.host}/common/courses/${courseId}/review/${item.id}/picture`
            })).sort((a, b) => {
                return a.id - b.id
            })

            if(!data.thanksToCourse.length)
                data.thanksToCourse = ["",""]

            data.thanksToCourse = data.thanksToCourse.map((value)=>{
                return {
                    value
                }
            })

            return {
                ...data,

                author: {
                    ...data.author,
                    picture: `${store.state.host}/common/courses/${courseId}/author/picture`,
                    certificatesPhoto
                },

                picture: `${store.state.host}/common/courses/${courseId}/picture`,

                reviews: [...reviews],
                initialReviews: [...reviews],
                addedReviews: [],
                deletedReviews: [],
                deletedCertificates: [],

                videoIntro: {
                    path: responses[3].path,
                    preview: responses[3].filePreview,
                },
                themes: themes
                // lessons: lessons
            }
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.reject(error)
        }
    }

    async getAuthorCertificatesById(courseId) {
        const response = await courseRepository.getAuthorCertificatesById(courseId)

        let promises = []

        response.forEach(item=>{
            promises.push(this.getAuthorCertificatePhotoById(courseId, item.id).then(photo=>{
                return {
                    ...item,
                    photo
                }
            }))
        })

        promises = await Promise.all(promises)

        const certificatesPhoto = [{},{},{}]

        promises.forEach((item,i) => {
            certificatesPhoto[i] = item})

        return certificatesPhoto
    }
    async getAuthorCertificatePhotoById(courseId, certificateId) {
        const response = await courseRepository.getAuthorCertificatePhotoById(courseId, certificateId)
        return response
    }

    async addAuthorCertificates(courseId, files) {
        const formData = new FormData()
        files.forEach(file=>formData.append('files',file))

        const response = await courseRepository.addAuthorCertificates(courseId,formData)
        return response.data
    }

    async deleteAuthorCertificates(courseId, photoId) {
        const response = await courseRepository.deleteAuthorCertificates(courseId,photoId)
        return response.data
    }

    async updateAuthorCertificates(courseId, certificateId, file) {
        const formData = new FormData()
        formData.append('files',file)

        const response = await courseRepository.updateAuthorCertificates(courseId, certificateId, formData)
        return response.data
    }

    async addCourse(course) {
        try {
            const courseFormData = new FormData()

            courseFormData.append('data', JSON.stringify({
                name: course.courseName,
                certificate_template_id: course.certificateTemplateId,
                price: 100,
                old_price: 0,
                is_purchasable: course.isPurchasable,
                is_public: course.isPublic,
                categories: course.categories || [],
                thanks_to_course: [],
                reviews: [
                    {author: '', text: ''},
                    {author: '', text: '',}],

                author: {name: '', achievements: ['', '', ''], about: '', position: ''},

                learning_topics: [
                    {author: '', text: ''},
                    {author: '', text: ''},
                    {author: '', text: ''},
                    {author: '', text: ''}],

                color: '#FFD0D9'
            }))

            return await courseRepository.addCourse(courseFormData)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.reject(error)
        }
    }

    async updatePurchasableStatus(course) {
        try {
            return await courseRepository.updatePurchasableStatus(course)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: true})
            return Promise.reject(error)
        }
    }

    async updatePublicStatus(course) {
        try {
            return await courseRepository.updatePublicStatus(course)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: true})
            return Promise.reject(error)
        }
    }

    async updateCourse(course) {
        try {
            const formData = new FormData()

            let savedCourse = {}

            if (course.picture && checkIsFile(course.picture)) {
                formData.append('course', course.picture)
            }
            if (course.author.picture && checkIsFile(course.author.picture)) {
                formData.append('author', course.author.picture)
            }

            const newAuthor = {...course.author}
            delete newAuthor.picture
            delete newAuthor.certificatesPhoto

            savedCourse.author = newAuthor

            savedCourse.create_reviews = course.addedReviews.map(addedReview => ({
                author: addedReview.author,
                text: addedReview.text,
                picture: addedReview.picture
            }))
            savedCourse.delete_reviews = course.deletedReviews
            savedCourse.update_reviews = course.initialReviews.map(review => ({...review}))

            savedCourse.update_reviews.forEach((review, i) => {
                if (review.picture && checkIsFile(review.picture)) {
                    formData.append(`update_reviews${i}`, review.picture)
                }
                delete review.picture
            })

            savedCourse.create_reviews.forEach((review, i) => {
                if (review.picture && checkIsFile(review.picture)) {
                    formData.append(`create_reviews${i}`, review.picture)
                }
                delete review.picture
            })

            const ignoredFields = new Set(
                ['id', 'initialReviews', 'addedReviews', 'deletedReviews',
                    'deletedCertificates', 'updatedReviews',
                    'reviews', 'coursePicture', 'author', 'lessons', 'picture','videoIntro', 'themes'])

            if (!course.description) ignoredFields.add('description')

            if(course.oldPrice === null)
                course.oldPrice = 0

            savedCourse = {
                ...savedCourse,
                ...objectCamelToUnderscore(course, ignoredFields)
            }

            savedCourse.thanks_to_course = savedCourse.thanks_to_course.map(item=>{
                return item.value
            })

            formData.append('data', JSON.stringify(savedCourse))

            const promises = []

            let addAuthorCertificatesPhoto = course.author.certificatesPhoto.map(certificate => {
                if (!certificate.id && !certificate.path && certificate.photo)
                    return certificate.photo
            }).filter(item => item)

            if (addAuthorCertificatesPhoto.length > 0)
                promises.push(this.addAuthorCertificates(course.id, addAuthorCertificatesPhoto))

            course.deletedCertificates.forEach(id => {
                promises.push(this.deleteAuthorCertificates(course.id, id))
            })


            course.author.certificatesPhoto.filter(certificate => certificate.id && certificate.photo && certificate.photo.name)
                .forEach(item => {
                    promises.push(this.updateAuthorCertificates(course.id, item.id, item.photo))
                })

            promises.push(courseRepository.updateCourse(course.id, formData))

           const response = await Promise.all([...promises])
            return response[response.length - 1]
        }
         catch (error) {
            notificationsHelper.fromHttpError(error, {base: false,
                backend: {find: 'One or more images has unknown file type', set: 'Одно или несколько изображений имеют неизвестный тип файла' }})
            return Promise.reject(error)
        }
    }

    async updateCategoriesCourse(course) {
        try {
            const courseId = course.id
            delete course.id

            return await courseRepository.updateCategoriesCourse(courseId, course)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.reject(error)
        }
    }

    async deleteCourse(courseId) {
        try {
            const data = await courseRepository.deleteCourse(courseId)
            return Promise.resolve(data)
        } catch (error) {
            notificationsHelper.fromHttpError(error, {base: false, backend: false})
            return Promise.reject(error)
        }
    }

    async getLessons(courseId) {
        const lessons = await courseRepository.getLessons(courseId)
        return Promise.resolve(lessons)
    }

    async getLesson(lessonId) {
        const lesson = await courseRepository.getLesson(lessonId)
        return Promise.resolve(lesson)
    }

    async updateQueueNumberLesson(lesson) {
        let copyLesson = {
            queueNumber: lesson.queueNumber,
            name: lesson.name,
        }

        const response = await courseRepository.updateQueueNumberLesson(copyLesson, lesson.id)
        return Promise.resolve(response)
    }

    async deleteLesson(themeId, lessonId) {
        const response = await courseRepository.deleteLesson(themeId, lessonId)
        return Promise.resolve(response)
    }

    async addAnswersForLesson(answers, lessonId) {
        const promises = []
        answers.forEach(answer => {
            promises.push(courseRepository.addAnswerForLesson(answer, lessonId).then(data => {
                answer.id = data.id
            }))
        })
        const responses = await Promise.all(promises)
        return Promise.resolve(responses)
    }

    async deleteAnswersForLesson(answers, lessonId) {
        const promises = []

        answers.forEach(answer => {
            promises.push(courseRepository.deleteAnswerForLesson(answer, lessonId))
        })

        const responses = await Promise.all(promises)
        return Promise.resolve(responses)
    }

    async updateAnswersForLesson(answers, lessonId) {
        const promises = []
        answers.forEach(answer => {
            promises.push(courseRepository.updateAnswerForLesson(answer, lessonId))
        })
        const responses = await Promise.all(promises)
        return Promise.resolve(responses)
    }

    async addAttachmentsForLesson(attachments, lessonId, typeLesson) {
        const promises = []

        const cancelToken = {
            id: lessonId,
            cancelToken: typeLesson === 'video' ? axios.CancelToken.source() : null
        }

        if (this.downloadLesson.find(item => item.id === lessonId) && typeLesson === 'video') {
            this.downloadLesson.find(item => item.id === lessonId).cancelToken.cancel()
            this.downloadLesson.splice(this.downloadLesson.findIndex(item => item.id === lessonId), 1)
        }

        if(typeLesson === 'video')
            this.downloadLesson.push(cancelToken)

        attachments.forEach(attachment => {
            promises.push(courseRepository.addAttachmentForLesson(attachment, lessonId, cancelToken.cancelToken?.token, attachment.type || 'default').then(response => {
                this.downloadLesson.splice(this.downloadLesson.findIndex(item => item.id === lessonId), 1)
                attachment = response.data
                return response.data
            }))
        })

        const responses = await Promise.all(promises)
        return Promise.resolve(responses)
    }

    async updateAttachmentsForLesson(attachments, lessonId, typeLesson) {

        const promises = []

        const cancelToken = {
            id: lessonId,
            cancelToken: typeLesson === 'video' ? axios.CancelToken.source() : null
        }

        if (this.downloadLesson.find(item => item.id === lessonId)) {
            this.downloadLesson.find(item => item.id === lessonId).cancelToken.cancel()
            this.downloadLesson.splice(this.downloadLesson.findIndex(item => item.id === lessonId), 1)
        }

        if(typeLesson === 'video')
            this.downloadLesson.push(cancelToken)

        attachments.forEach(attachment => {
            promises.push(courseRepository.updateAttachmentForLesson(attachment, lessonId, cancelToken.cancelToken?.token).then(() => {
                this.downloadLesson.splice(this.downloadLesson.findIndex(item => item.id === lessonId), 1)
            }))
        })

        const responses = await Promise.all(promises)
        return Promise.resolve(responses)
    }

    async addOrUpdateVideoLinkForLesson(lessonId, attachment){
        if (attachment.id) {
            const response = courseRepository.updateYouTubeLinkForLesson(lessonId, attachment)
            return Promise.resolve(response)
        } else {
            const response = courseRepository.addYouTubeLinkForLesson(lessonId, attachment)
            return Promise.resolve(response)
        }
    }

    async deleteAttachmentsForLesson(idAttachments, lessonId) {
        const promises = []
        idAttachments.forEach(attachment => {
            promises.push(courseRepository.deleteAttachmentForLesson(attachment, lessonId))
        })
        const responses = await Promise.all(promises)
        return Promise.resolve(responses)
    }

    async getLessonIntro(courseId){
        try {
            const promises = []

            promises.push(courseRepository.getLessonIntro(courseId))
            promises.push(courseRepository.getPreviewForLessonIntro(courseId))

            const responses = await Promise.all(promises)

            return {
                ...responses[0],
                ...responses[1]
            }
        }catch (error) {
            console.log(error)
            return  {
                courseId: courseId,
                isVisible: false,
                path:'',
                filePreview: null
            }
        }

    }
    async addAttachmentLinkIntro(lesson,link){
        if (this.downloadVideoIntroLesson.find(item => item.id === lesson.courseId)) {
            this.downloadVideoIntroLesson.find(item => item.id === lesson.courseId).cancelToken.cancel()
            this.downloadVideoIntroLesson.splice(this.downloadVideoIntroLesson.findIndex(item => item.id === lesson.courseId), 1)
        }

        const response = await courseRepository.addAttachmentLinkIntro(lesson,link)
        return response
    }
    async addAttachmentPreviewIntro(lesson,preview){
        const formData = new FormData()
        formData.append('file',preview)

        const response = await courseRepository.addAttachmentPreviewIntro(lesson,formData)
        return response
    }
    async addAttachmentVideoIntro(lesson, file){

        const cancelToken = {
            id: lesson.courseId,
            cancelToken: axios.CancelToken.source()
        }

        if (this.downloadVideoIntroLesson.find(item => item.id === lesson.courseId)) {
            this.downloadVideoIntroLesson.find(item => item.id === lesson.courseId).cancelToken.cancel()
            this.downloadVideoIntroLesson.splice(this.downloadVideoIntroLesson.findIndex(item => item.id === lesson.courseId), 1)
        }

        this.downloadVideoIntroLesson.push(cancelToken)

        const response = await courseRepository.addAttachmentVideoIntro(lesson, file,cancelToken.cancelToken?.token)

        this.downloadVideoIntroLesson.splice(this.downloadVideoIntroLesson.findIndex(item => item.id === lesson.courseId), 1)

        return response
    }

    async updateAttachmentVideoIntro(lesson, file){

        const cancelToken = {
            id: lesson.courseId,
            cancelToken: axios.CancelToken.source()
        }

        if (this.downloadVideoIntroLesson.find(item => item.id === lesson.courseId)) {
            this.downloadVideoIntroLesson.find(item => item.id === lesson.courseId).cancelToken.cancel()
            this.downloadVideoIntroLesson.splice(this.downloadVideoIntroLesson.findIndex(item => item.id === lesson.courseId), 1)
        }

        this.downloadVideoIntroLesson.push(cancelToken)

        const response = await courseRepository.updateAttachmentVideoIntro(lesson, file,cancelToken.cancelToken?.token)

        this.downloadVideoIntroLesson.splice(this.downloadVideoIntroLesson.findIndex(item => item.id === lesson.courseId), 1)

        return response
    }
    async updateAttachmentLinkIntro(lesson,link){
        if (this.downloadVideoIntroLesson.find(item => item.id === lesson.courseId)) {
            this.downloadVideoIntroLesson.find(item => item.id === lesson.courseId).cancelToken.cancel()
            this.downloadVideoIntroLesson.splice(this.downloadVideoIntroLesson.findIndex(item => item.id === lesson.courseId), 1)
        }

        const response = await courseRepository.updateAttachmentLinkIntro(lesson, link)
        return response
    }
    async updatePreviewIntro(lesson,preview){

        courseRepository.deletePreviewIntoLesson(lesson.courseId).finally(async ()=>{
            const formData = new FormData()
            formData.append('file',preview)

            const response = await courseRepository.addAttachmentPreviewIntro(lesson,formData)
            return response.data
        })

    }

    async updateStatusVideoIntro(lesson){
        const response = await courseRepository.updateStatusVideoIntro(lesson)
        return response.data
    }

    async deleteIntoLesson(lesson){
        if (this.downloadVideoIntroLesson.find(item => item.id === lesson.courseId)) {
            this.downloadVideoIntroLesson.find(item => item.id === lesson.courseId).cancelToken.cancel()
            this.downloadVideoIntroLesson.splice(this.downloadVideoIntroLesson.findIndex(item => item.id === lesson.courseId), 1)
        }

        const response = await courseRepository.deleteIntoLesson(lesson.courseId)
        notificationsHelper.success('Вводный урок удален')
        return response.data
    }

    async getCourseImage(courseId) {
        try {
            return await courseRepository.getCourseImage(courseId)
        } catch {
            return Promise.resolve(null)
        }
    }

    async getCourseAuthorImage(courseId) {
        try {
            return await courseRepository.getCourseAuthorImage(courseId)
        } catch {
            return Promise.resolve(null)
        }
    }

    async getCourseReviewPicture(courseId, reviewId) {
        try {
            return await courseRepository.getCourseReviewPicture(courseId, reviewId)
        } catch {
            return Promise.resolve(null)
        }
    }

    async getThemes(courseId) {
        try {
            const themes = await courseRepository.getThemes(courseId)

            return themes.map(item => ({
                ...item,
                lessons: item.lessons.map(lesson => ({
                    ...lesson,
                    attachments: lesson?.attachments || [],
                    answers: lesson?.answers || []
                }))
            }))
        } catch (error) {
            return []
        }
    }

    async createTheme(courseId, theme) {
        return await courseRepository.createTheme(courseId, theme)
    }

    async updateTheme(courseId, theme) {
        await courseRepository.updateTheme(courseId, theme.id, { name: theme.name, courseId })
    }

    async deleteTheme(courseId, themeId) {
        await courseRepository.deleteTheme(courseId, themeId)
    }

    async addLessons(lesson) {

        const ignoredFields = new Set(['attachments'])
        // if (lesson.type !== 'task')
        //     ignoredFields.add('answers')

        const newLesson = {
            ...objectCamelToUnderscore(lesson, ignoredFields)
        }


        const response = await courseRepository.addLesson(newLesson)
        notificationsHelper.success('Урок добавлен')

        return Promise.resolve(response)
    }

    async createLesson(themeId, lesson) {

        if (!lesson.question) {
            delete lesson.question
        }

        const ignoredFields = new Set(['attachments', 'id', 'position', 'answers'])

        const newLesson = {
            ...objectCamelToUnderscore(lesson, ignoredFields)
        }

        return await courseRepository.createLesson(themeId, newLesson)
    }

    async updatePositionsForTheme(themeId, lessons) {
        await Promise.all(
            lessons.map((id, index) =>
                courseRepository.updateLesson(themeId, id, { position: index + 1 })
            )
        )
    }

    async updateLesson(themeId, lesson, idDeletedAnswers, video, idDeletedFiles, isRemoveVideo) {

        if (!lesson.question) {
            delete lesson.question
        }

        if (isRemoveVideo) {
            const id = lesson.attachments?.find(item => item.type === 'video')?.id
            if (id) {
                idDeletedFiles.push(id)
            }
        }

        if (idDeletedFiles?.length) {
            await this.deleteAttachmentsForLesson(idDeletedFiles, lesson.id)
        }

        if (idDeletedAnswers?.length) {
            await this.deleteAnswersForLesson(idDeletedAnswers, lesson.id)
        }

        const newAnswers = lesson.answers?.filter(item => !item.id)
        if (newAnswers?.length) {
            await this.addAnswersForLesson(newAnswers.map(item => ({ isRight: item.isRight, text: item.text })), lesson.id)
        }
        const oldAnswers = lesson.answers?.filter(item => item.id)
        if (oldAnswers?.length) {
            await this.updateAnswersForLesson(oldAnswers, lesson.id)
        }

        const newAttachments = lesson.attachments
            .filter(item => item.file)
        const oldAttachments = lesson.attachments
            .filter(item => !item.file && item.path)
        // if (video?.value) {
        //     newAttachments.push({ lessonId: lesson.id, file: video.value, type: 'video' })
        // }
        if (newAttachments.length) {
            const attachments = await this.addAttachmentsForLesson(newAttachments, lesson.id)
            lesson.attachments = lesson.attachments
                .filter(item => item.id)
                .concat(attachments)
        }
        if (oldAttachments) {
            await Promise.all(
                oldAttachments.map(item =>
                    courseRepository.updateAttachmentTitle(lesson.id, item)
                )
            )
        }
        // if (video?.path) {
        //     await this.addOrUpdateVideoLinkForLesson(lesson.id, video)
        // }

        const ignoredFields = new Set([
            'id',
            'themeId',
            'attachments',
            'answers',

            'subject',
            'queue_number',
            'queueNumber',
            'position'
        ])

        const newLesson = {
            ...objectCamelToUnderscore(lesson, ignoredFields)
        }

        await courseRepository.updateLesson(themeId, lesson.id, newLesson)
    }

}

export const courseService = new CourseService()
