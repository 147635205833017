<template>
  <div class="admin-courses">
    <course-header-editor :course="course"></course-header-editor>
<!--    <course-thanks-editor :thanksCourse="course.thanksToCourse"/>-->
<!--    <course-description-editor :course="course"></course-description-editor>-->
    <course-study-editor :learning-topics="course.learningTopics"></course-study-editor>
    <course-program-editor :course="course"></course-program-editor>
<!--    <course-author-editor :author="course.author"></course-author-editor>-->
<!--    <course-author-certificates :course="course"/>-->
<!--    <course-reviews-editor :course="course"></course-reviews-editor>-->
    <course-certificate v-model="course.certificateTemplateId"/>
  </div>
</template>

<script>
import CourseHeaderEditor from "./components/CourseHeaderEditor";
// import CourseThanksEditor from "./components/CourseThanksEditor";
// import CourseDescriptionEditor from "./components/CourseDescriptionEditor";
import CourseStudyEditor from "./components/CourseStudyEditor";
// import CourseAuthorEditor from "./components/CourseAuthorEditor";
// import CourseAuthorCertificates from "./components/СourseAuthorCertificates/CourseАuthorСertificates"
// import CourseReviewsEditor from "./components/CourseReviewsEditor";
import CourseProgramEditor from "./components/CourseProgramEditor/CourseProgramEditor";
import CourseCertificate from "@/app/admin/course/components/courseEditor/components/CourseCertificate";

export default {
  name: "CourseEditor",
  components: {
    // CourseThanksEditor,
    // CourseReviewsEditor,
    // CourseAuthorEditor,
    // CourseAuthorCertificates,
    CourseStudyEditor,
    // CourseDescriptionEditor,
    CourseHeaderEditor,
    CourseProgramEditor,
    CourseCertificate
  },
  props: {
    course: Object
  }
}
</script>

<style scoped lang="scss">

</style>
