<template>
  <div>
    <course-header-info
        :name="course.name"
        :price="course.price"
        :lessons-count="course.lessonsCount"
        :author="course.author"
        :available="course.isPurchasable"
        :video-intro="course.videoIntro"/>
<!--    <course-thanks-info :thanks-course="course.thanksToCourse"/>-->
<!--    <course-description-info-->
<!--        :tasks-count="course.tasksCount"-->
<!--        :videos-count="course.videosCount"/>-->
    <course-study-info
        :learning-topics="course.learningTopics"/>
    <course-program-info
        :course="course"
        :purchased="true"/>
<!--    <course-author-info-->
<!--        :show-btn-buy="true"-->
<!--        :price="course.price"-->
<!--        :author="course.author"/>-->
<!--    <course-author-certificates-info-->
<!--        :photos="course.author.certificatesPhoto"/>-->
<!--    <course-reviews-info-->
<!--        :reviews="course.reviews"/>-->
    <course-certificate
        :certificate-template-id="course.certificateTemplateId"/>

    <buy-course-popup :courseInfo="course"/>
    <not-available-course/>
  </div>
</template>

<script>
import CourseHeaderInfo from "./components/CourseHeaderInfo";
// import CourseDescriptionInfo from "@/app/user/courseInfo/components/CourseDescriptionInfo";
import CourseStudyInfo from "@/app/user/courseInfo/components/CourseStudyInfo";
// import CourseAuthorInfo from "@/app/user/courseInfo/components/CourseAuthorInfo";
import CourseProgramInfo from "@/app/user/courseInfo/components/CourseProgramInfo/CourseProgramInfo";
// import CourseReviewsInfo from "@/app/user/courseInfo/components/CourseReviewsInfo";
import CourseCertificate from "@/app/admin/course/components/courseInfo/components/CourseCertificate";
// import CourseAuthorCertificatesInfo from "../../../../user/courseInfo/components/CourseAuthorCertificatesInfo";
// import CourseThanksInfo from "../../../../user/courseInfo/components/CourseThanksInfo";
import BuyCoursePopup from "../../../../user/myCourses/components/BuyCoursePopup";
import NotAvailableCourse from "../../../../user/myCourses/components/NotAvailableCourse";

export default {
  name: "CourseInfo",
  components: {
    BuyCoursePopup,
    NotAvailableCourse,
    // CourseReviewsInfo,
    // CourseAuthorInfo,
    CourseStudyInfo,
    CourseProgramInfo,
    // CourseDescriptionInfo,
    CourseHeaderInfo,
    CourseCertificate,
    // CourseThanksInfo
    // CourseAuthorCertificatesInfo
  },
  props: {
    course: Object
  },
  // computed: {
  //   testsCount() {
  //     return this.course.lessons.filter(lesson => lesson.type === 'task').length
  //   }
  // }
}
</script>

<style scoped>

</style>
