<template>
  <div class="form-input" :class="[{'form-input--error': isError}, {'form-input--disabled': disabled}]">
    <span class="form-input__error-text" v-if="isError">{{validationErrorMessage}}</span>

    <label class="form-input__label" :class="{'form-input__label--required': required}">
      <span v-if="!required">{{ label }}</span>
      <input class="form-input__input"
             :style="{'letter-spacing': inputType === 'password' && value ? '8px' : ''}"
             :type="inputType"
             :readonly="readonly || disabled"
             v-mask="mask"
             :placeholder="placeholder"
             :maxLength="maxLength"
             :value="value"
             @focus="$emit('focus')"
             @input="$emit('input', $event.target.value)">
      <img v-if="type === 'password'"
          class="form-input__eye"
           v-show="type === 'password' && value"
           @click="isShowPassword = !isShowPassword"
           :src="require(`../assets/img/admin-login/${!isShowPassword ? 'eye-visible.svg' : 'eye-invisible.svg'}`)"
           alt="eye">
      <img class="form-input__eye"
           v-show="type === 'password' && value"
           @click="isShowPassword = !isShowPassword"
           :src="require(`../assets/img/input/${!isShowPassword ? 'eye-visible.svg' : 'eye-invisible.svg'}`)"
           alt="eye">
    </label>

  </div>
</template>

<script>
import {validationMap} from "../utils/validators";

export default {
  name: "FormInput",
  props: {
    value: String,
    label: String,
    placeholder: { type: String, default: 'Input please' },
    readonly: Boolean,
    validator: Object,
    type: { type: String, default: 'text' },
    clearable: { type: Boolean, default: false },
    mask: { type: String, default: null },
    required: { type: Boolean, default: false },
    maxLength: { type: String, default: null },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    inputType() {
      if (this.type !== 'password') return this.type
      else {
        if (!this.isShowPassword) return 'password'
        else return 'text'
      }
    },

    isError() {
      return this.validator && this.validator.$error
    },

    validationErrorMessage() {
      for (const validationItem in this.validator) {
        if (Object.prototype.hasOwnProperty.call(this.validator, validationItem) && validationItem[0] !== '$') {
          if (this.validator[validationItem] === false) return validationMap[validationItem](this.validator)
        }
      }

      return ''
    }
  },
  data() {
    return {
      isShowPassword: false,
    }
  }
}
</script>

<style scoped lang="scss">

.form-input {
  position: relative;
  margin: 7px 0px;
  &__error-text {
    position: absolute;
    top: 2px;
    right: 0;

    min-height: 17px;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;

    color: #FF0000;
  }
  &--error {
    .form-input__label {
      color: red;
    }
  }
  &__label {
    position: relative;
    display: flex;
    flex-direction: column;

    font-family: Roboto ,sans-serif;
    font-size: 14px;
    color: #404040;
  }

  &__input {
    outline: none;
    height: 36px;
    border: 0.5px solid #D7D7D7;
    border-radius: 4px;
    padding-right: 35px;
    padding-left: 14px;
    margin-top: 7px;
    font-size: 14px;
    color: #1B1B1B;
    font-weight: normal;

    transition: .2s;

    &:focus {
      border-color: #7B8DFF;
    }

    &::placeholder {
      color: #9D9D9D;
      font-size: 12px;
    }
  }

  &__eye {
    position: absolute;
    right: 13px;
    bottom: 12px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.form-input--disabled {
  .form-input__input {
    background-color: #F6F6F6;
    cursor: no-drop;
    
    &:focus {
      border-color: #D7D7D7;;
    }
  }
}
.form-input--error {
  .form-input__input {
    border-color: red;
  }
}
</style>
