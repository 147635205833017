export default function cancelableWrapper(promise) {
    let isCancelled = false

    const next = promise.then(value => {
        if (isCancelled) return Promise.reject(new Error("Cancelled"))
        else return value
    })

    next.cancel = () => {
        isCancelled = true
    }

    return next
}