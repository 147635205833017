import http from "@/app/user/config/user-axios.config";

class MyCoursesRepository {
    
    async buyCourse(courseId, params) {
        const response = await http.post(`/spa/courses/${courseId}/buy`, {},params)
        return response.data
    }

    async getMyCourses() {
        const response = await http.get('/spa/courses/my_courses')
        return response.data
    }

    async getProgressByCourse(courseId) {
        const response = await http.get(`/spa/courses/${courseId}/progress`)
        return response.data
    }
    
}

export const myCoursesRepository = new MyCoursesRepository()
