import http from '@/app/admin/config/admin-axios.config'
import store from '../../../store/index'
import axios from "axios";
import ProgressNotification from "./progressNotification/progressNotification";

class CourseRepository {
    async getCategories() {
        const response = await http.get(store.state.host + '/common/categories')
        return Promise.resolve(response.data)
    }

    async addCategory(categoryName) {
        const response = await http.put('/categories', {name: categoryName})
        return Promise.resolve(response.data)
    }

    async updateCategory({id, name}) {
        const response = await http.patch(`/categories/${id}`, {name})
        return Promise.resolve(response.data)
    }

    async deleteCategory(directionId) {
        const response = await http.delete(`/categories/${directionId}`)
        return Promise.resolve(response.data)
    }

    async getCourses() {
        const response = await http.get('/courses/full')
        return Promise.resolve(response.data)
    }

    async getCourseById(courseId) {
        const response = await http.get(`/courses/${courseId}`)
        return Promise.resolve(response.data)
    }

    async getAuthorCertificatesById(courseId) {
        const response = await http.get(`/courses/${courseId}/author_certificates`)
        return Promise.resolve(response.data)
    }

    async getAuthorCertificatePhotoById(courseId, certificateId) {
        const response = await http.get(`/courses/${courseId}/author_certificates/${certificateId}?t=${Date.now().toString()}`, {responseType: 'blob'})
        return Promise.resolve(response.data)
    }

    async addAuthorCertificates(courseId, files) {
        const response = await http.put(`courses/${courseId}/author_certificates`, files)
        return Promise.resolve(response.data)
    }

    async updateAuthorCertificates(courseId, certificateId, files) {
        const response = await http.patch(`courses/${courseId}/author_certificates/${certificateId}`, files)
        return Promise.resolve(response)
    }

    async deleteAuthorCertificates(courseId, photoId) {
        const response = await http.delete(`courses/${courseId}/author_certificates/${photoId}`)
        return Promise.resolve(response)
    }

    async addCourse(course) {
        const response = await http.put(`/courses`, course)
        return Promise.resolve(response.data)
    }

    async updatePurchasableStatus(course) {
        const response = await http.patch(`/courses/${course.id}/is_purchasable`, {is_purchasable: course.isPurchasable})
        return response.data
    }

    async updatePublicStatus(course) {
        const response = await http.patch(`/courses/${course.id}/is_public`, {is_public: course.isPublic})
        return response.data
    }

    async updateCourse(courseId, courseFormData) {
        const response = await http.patch(`/courses/${courseId}`, courseFormData)
        return response.data
    }

    async updateCategoriesCourse(courseId, categories) {
        const response = await http.patch(`/courses/${courseId}/categories`, categories)
        return response.data
    }

    async deleteCourse(courseId) {
        const response = await http.delete(`/courses/${courseId}`)
        return Promise.resolve(response.data)
    }

    async getCourseImage(courseId) {
        const response = await http.get(store.state.host + `/common/courses/${courseId}/picture`, {responseType: 'blob'})
        return Promise.resolve(response.data)
    }

    async getCourseAuthorImage(courseId) {
        const response = await http.get(store.state.host + `/common/courses/${courseId}/author/picture`, {responseType: 'blob'})
        return Promise.resolve(response.data)
    }

    async getCourseReviewPicture(courseId, reviewId) {
        const response = await http.get(`/courses/${courseId}/review/${reviewId}/picture`, {responseType: 'blob'})
        return Promise.resolve(response.data)
    }

    async getLessons(courseId) {
        const response = await http.get(`/courses/${courseId}/lessons`)
        return Promise.resolve(response.data)
    }

    async getLesson(lessonId) {
        const response = await http.get(`/lessons/${lessonId}`)
        return Promise.resolve(response.data)
    }

    async updateQueueNumberLesson(lesson, idLesson) {
        const response = await http.patch(`/lessons/${idLesson}`, lesson)
        return Promise.resolve(response.data)
    }

    async deleteLesson(themeId, lessonId) {
        const response = await http.delete(`/themes/${themeId}/lesson/${lessonId}`)
        return Promise.resolve(response.data)
    }

    async addAnswerForLesson(answer, lessonId) {
        const response = await http.put(`/lessons/${lessonId}/answer`, answer)
        return Promise.resolve(response.data)
    }

    async deleteAnswerForLesson(answerId, lessonId) {
        const response = await http.delete(`/lessons/${lessonId}/answer/${answerId}`)
        return Promise.resolve(response.data)
    }

    async updateAnswerForLesson(answer, lessonId) {
        const response = await http.patch(`/lessons/${lessonId}/answer/${answer.id}`, {
            isRight: answer.isRight,
            text: answer.text
        })
        return Promise.resolve(response.data)
    }

    async addAttachmentForLesson(attachment, lessonId, cancelToken = null, type = 'default') {

        const progress = {fileName: attachment.file?.name || attachment.path.name, progress: 0}
        ProgressNotification.add(progress)

        const attachmentFileName = attachment.title || attachment.file.name

        const requestBody = attachment.file ? attachment.file : attachment.path

        return http.put(
            `/lessons/${lessonId}/attachment?filename=${attachmentFileName}&type=${type}`,
            requestBody,
            {
                cancelToken: cancelToken,
                headers: {
                    'content-type': requestBody.type
                },
                onUploadProgress: progressEvent => {
                    progress.progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                }
            }).catch(error => {
            if (axios.isCancel(error))
                progress.progress = null
            throw error
        })
    }

    async updateAttachmentTitle(lessonId, attachment) {
        return http.patch(`/lessons/${lessonId}/attachment/${attachment.id}?filename=${attachment.title}&type=${attachment.type || 'default'}`)
    }

    async deleteAttachmentForLesson(attachmentId, lessonId) {
        const response = await http.delete(`/lessons/${lessonId}/attachment/${attachmentId}`)
        return Promise.resolve(response.data)
    }

    async updateAttachmentForLesson(attachment, lessonId, cancelToken = null) {

        const progress = {fileName: attachment.file?.name || attachment.path.name, progress: 0}
        ProgressNotification.add(progress)

        const attachmentFileName = attachment.path?.name || attachment.file.name

        const requestBody = attachment.file ? attachment.file : attachment.path

        if (attachmentFileName !== null) {
            const response = await http.patch(
                `/lessons/${lessonId}/attachment/${attachment.id}?filename=${attachmentFileName}`,
                requestBody,
                {
                    cancelToken: cancelToken,
                    header: {
                        'content-type': requestBody.type
                    },
                    onUploadProgress: progressEvent => {
                        progress.progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                    }
                }
            ).catch(error => {
                if (axios.isCancel(error))
                    progress.progress = null
                throw error
            })
            return Promise.resolve(response.data)
        }
    }

    async updateYouTubeLinkForLesson(lessonId, attachment) {
        const response = await http.patch(`/lessons/${lessonId}/attachment/${attachment.id}/link`, {
                path: attachment.path,
                title: 'YouTube',
                type: 'video'
            })
        return Promise.resolve(response.data)
    }

    async addYouTubeLinkForLesson(lessonId, attachment) {
        const response = await http.put(`/lessons/${lessonId}/attachment/link`, {
                path: attachment.path,
                title: 'YouTube',
                type: 'video'
            })
        return Promise.resolve(response.data)
    }

    async getLessonIntro(courseId) {
        const response = await http.get(`/courses/${courseId}/entry_lesson`)
        return response.data
    }

    async getPreviewForLessonIntro(courseId) {
        try {
            const response = await http.get(store.state.host + `/common/courses/${courseId}/entry_lesson/thumbnail`, {responseType: 'blob'})
            const nameResponse = await http.get(store.state.host + `/common/courses/${courseId}/entry_lesson/thumbnail/name`)

            return {
                filePreview: response.data,
                namePreview: nameResponse.data.name
            }
        } catch (error) {
            return {
                filePreview: null,
                namePreview: null
            }
        }

    }

    async addAttachmentLinkIntro(lesson, link) {
        const response = await http.put(`/courses/${lesson.courseId}/entry_lesson/link?is_visible=${lesson.isVisible}`, {link: link})
        return response.data
    }

    async addAttachmentPreviewIntro(lesson, formData) {
        const response = await http.put(`/courses/${lesson.courseId}/entry_lesson/thumbnail`, formData)
        return response.data
    }

    async addAttachmentVideoIntro(lesson, file, cancelToken = null) {
        const progress = {fileName: file.name, progress: 0}
        ProgressNotification.add(progress)

        const response = await http.put(`/courses/${lesson.courseId}/entry_lesson/video`, file,
            {
                params: {
                    is_visible: lesson.isVisible,
                    title: file.name
                },
                onUploadProgress: progressEvent => {
                    progress.progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                },
                cancelToken: cancelToken,
            }).catch(error => {
            if (axios.isCancel(error))
                progress.progress = null
            throw error
        })
        return response.data
    }

    async updateAttachmentLinkIntro(lesson, link) {
        const response = await http.patch(`/courses/${lesson.courseId}/entry_lesson/link`, {link: link})
        return response.data
    }

    async updateAttachmentVideoIntro(lesson, file, cancelToken = null) {
        const progress = {fileName: file.name, progress: 0}
        ProgressNotification.add(progress)

        const response = await http.patch(`/courses/${lesson.courseId}/entry_lesson/video?title=${file.name}`, file, {
            onUploadProgress: progressEvent => {
                progress.progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
            },
            cancelToken: cancelToken,
        }).catch(error => {
            if (axios.isCancel(error))
                progress.progress = null
            throw error
        })

        return response.data
    }

    async updateStatusVideoIntro(lesson) {
        const response = await http.patch(`/courses/${lesson.courseId}/entry_lesson/is_visible`, {isVisible: lesson.isVisible})
        return response.data
    }

    async deleteIntoLesson(courseId) {
        const response = await http.delete(`/courses/${courseId}/entry_lesson`)
        return response.data
    }

    async deletePreviewIntoLesson(courseId) {
        const response = await http.delete(`/courses/${courseId}/entry_lesson/thumbnail`)
        return response
    }

    async getThemes(courseId) {
        const response = await http.get(`/course/${courseId}/themes`)
        return Promise.resolve(response.data.data)
    }

    async createTheme(courseId, theme) {
        const response = await http.put(`/course/${courseId}/theme`, theme)
        return response.data
    }

    async updateTheme(courseId, themeId, theme) {
        const response = await http.patch(`/course/${courseId}/theme/${themeId}`, theme)
        console.log(response)
    }

    async deleteTheme(courseId, themeId) {
        const response = await http.delete(`/course/${courseId}/theme/${themeId}`)
        return response.data
    }

    async addLesson(themeId, lesson) {
        const response = await http.put(`/themes/${themeId}/lesson`, lesson)
        return Promise.resolve(response.data)
    }

    async createLesson(themeId, lesson) {
        const response = await http.put(`/themes/${themeId}/lesson`, lesson)
        return response.data
    }

    async updateLesson(themeId, lessonId, lesson) {
        const response = await http.patch(`/themes/${themeId}/lesson/${lessonId}`, lesson)
        console.log(response)
    }

}

export const courseRepository = new CourseRepository()
