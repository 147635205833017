<template>
  <div class="course-program-editor" :key="refresh">
    <div class="course-video-intro__title">Темы</div>

    <theme
        v-for="(item, index) in themes" :key="item.id"
        :theme="item"
        :course="course"
        @add-lesson="addLesson(index)"
        @delete-theme="deleteTheme(index)"
        @delete-lesson="handleDeleteLesson"
        @change-position="handleChangePosition(index)"
    />

    <btn-for-lessons @addLesson="handleClickAddTheme"/>

  </div>
</template>

<script>
import BtnForLessons from "./components/BtnForLessons";
import Theme from "@/app/admin/course/components/courseEditor/components/CourseProgramEditor/components/Theme.vue";
import {mapGetters} from "vuex";
import { v4 as uuidv4 } from 'uuid';
import {courseService} from "@/app/admin/course/course.service";

export default {
  name: "CourseProgramEditor",
  components: {
    BtnForLessons,
    Theme
  },
  props: {
    course: Object,
  },
  data() {
    return {
      loading: false,
      themes: [],
      refresh: 0
    }
  },
  created() {
    if (this.course.themes) {
      this.themes = this.course.themes.map(item => ({ ...item }))
    }
  },
  computed: {
    ...mapGetters(['selectedCourseId']),
  },
  methods: {
    addLesson(index) {
      this.themes[index].lessons.push({
        id: uuidv4(),
        name: '',
        description: '',
        position: this.themes[index].lessons.length + 1,
        attachments: [],
        answers: [],
        question: null
      })
    },
    handleChangePosition(index) {
      this.themes[index].lessons = this.themes[index].lessons.sort((a, b) => a.position > b.position ? 1 : -1)
      ++this.refresh
    },
    handleClickAddTheme() {
      courseService.createTheme(this.course.id, {
        name: 'Новая тема'
      }).then(theme => this.themes.push({ ...theme, lessons: [] }))
    },
    deleteTheme(index) {
      this.themes.splice(index, 1)
    },
    handleDeleteLesson(themeId, lessonId) {
      const index = this.themes.findIndex(item => item.id === themeId)
      const lessonIndex = this.themes[index].lessons.findIndex(item => item.id === lessonId)
      this.themes[index].lessons.splice(lessonIndex, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.course-program-editor {
  display: flex;
  flex-direction: column;

  width: 790px;

  padding: 65px;
  border: 2px solid #DCDFE6;
  border-radius: 26px 100px 26px 26px;

  margin: 0 auto 140px auto;

  transition: 0.2s;

  @media screen and (max-width: 1200px) {
    margin: 0 auto 120px auto;
  }
  @media screen and (max-width: 980px) {
    margin: 0 auto 100px auto;
  }
  @media screen and (max-width: 740px) {
    margin: 0 auto 80px auto;
  }
  @media screen and (max-width: 500px) {
    margin: 0 auto 70px auto;
  }

  @media screen and (max-width: 700px){
    padding: 45px;
  }
  @media screen and (max-width: 600px){
    padding: 45px 30px;
  }
  @media screen and (max-width: 400px){
    padding: 0;
    border: none;
  }
}

</style>
