import store from '@/store'
import router from '@/router'
import {notificationsHelper} from '@/helpers/notifications.helper'
import {authRepository} from '@/app/admin/auth/auth.repository'
import {adminService} from "../adminUsers/admin.service";
import http from "../config/admin-axios.config";


class AuthService {
    
    logout() {
        store.commit('adminLogout')
        store.commit('adminStoreDestroy')
        router.push('/admin/login')
    }
    
    async auth(requestData) {
        try {
            const data = await authRepository.auth(requestData)
            adminService.getAdminInfo()
            store.commit('adminLogin', data)

            return Promise.resolve(requestData)
        } catch (error) {
            notificationsHelper.fromHttpError(error)
            throw error
        }
    }

    async refreshToken(){
        if (!store.state.admin.refreshPromise) {

            const promise = http.post('/refresh', {
                refresh_token: store.state.admin.adminRefreshToken
            }).then(response => {
                const accessToken = response.data.accessToken

                store.commit('setRefreshPromise', null)
                localStorage.setItem('adminAccessToken', accessToken)
                store.commit('setAccessToken', accessToken)

            }).catch(() => {
                store.commit('adminLogout')
            })
            store.commit('setRefreshPromise', promise)
            return promise
        } else return store.state.admin.refreshPromise
    }


}

export const authService = new AuthService()
