<template>
  <div class="video-lesson-wrapper"
       v-show="videos.length">
    <div class="video-lesson-wrapper__tabs">
      <button
          v-for="item in videos" :key="item.id"
          :class="{ active: selectedVideo && item.id === selectedVideo.id }"
          @click="handleClickVideo(item)"
      >
        <span>{{ item.title }}</span>
      </button>
    </div>
    <video-player
      v-if="playerOptions.sources[0].src && selectedVideo.typeFile === 'video'"
      class="vjs-custom-skin"
      :options="playerOptions"
      :playsinline="true"
      ref="videoPlayer"
    />
    <img v-if="playerOptions.sources[0].src && selectedVideo.typeFile === 'image'" class="video-lesson-img" :src="playerOptions.sources[0].src" alt="">
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'

import {videoPlayer} from 'vue-video-player'

import {lessonsService} from "../lessonsCourse.service"

export default {
  name: "VideoLesson",
  components: { videoPlayer },
  props: {
    lesson: Object
  },
  data() {
    return {
      selectedVideo: null,
      videos: [],
      notFound: false,
      vimeoOptions:{
        title: false,
        byline: false,
        width: 'auto',
      },
      playerVars:{
        rel: '0',
        showinfo: '0',
        iv_load_policy: '3',
        modestbranding: '1',
        controls: '1'
      },
      // playerOptions: {
      //   muted: true,
      //   language: 'ru',
      //   playbackRates: [0.7, 1.0, 1.5, 2.0],
      //   sources: [{
      //     type: "video/mp4",
      //     src: null
      //   }],
      //   poster: "",
      // },
    }
  },
  computed: {
    playerOptions() {
      return {
        muted: true,
        language: 'ru',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: this.selectedVideo.path
        }],
        poster: "",
      }
    }
  },
  created() {    
    this.videos = this.lesson.attachments
        ?.filter(item => item.type === 'video')
        .map(item => ({
          ...item,
          path: `${process.env.VUE_APP_BACKEND_HOST}/watch?path=${item.path}`,
          typeFile: item.path.includes('jpg') || item.path.includes('jpeg') || item.path.includes('png') || item.path.includes('gif') ? 'image' : 'video'
        }))
    this.selectedVideo = this.videos?.[0]
    // this.playerOptions.sources[0].src = `${process.env.VUE_APP_BACKEND_HOST}/watch?path=${videoPath}`
    // if (video?.path) {
    //   const videoPath = video?.path
    // }
  },
  methods: {
    handleClickVideo(video) {
      this.selectedVideo = video
    },
    passedLesson() {
      if (!this.lesson.isCompleted)
        lessonsService.passedLesson(this.lesson.id)
    },
    errorVimeo(){
      const div = document.createElement('div')
      div.innerText = 'Простите, видео временно недоступно'
      div.classList.add('video-lesson-wrapper__not-found')
      document.getElementsByClassName('video-lesson-wrapper')[0].appendChild(div)
    }
  }
}
</script>

<style lang="scss" scoped>
.video-lesson-wrapper {
  //height: 400px;
  z-index: 0;
}
</style>

<style lang="scss">
.video-lesson-wrapper {
  width: 100%;
  min-height: 500px;
  height: max-content;
  position: relative;
  margin-bottom: 15px;

  .video-lesson-img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  &__tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    height: auto!important;
    margin-bottom: 15px;
    > button {
      padding-left: 20px;
      padding-right: 20px;
      min-width: max-content;
      height: 48px;
      border-radius: 30px;
      background-color: #FFFFFF;
      color: #000000;
      border: 1px solid #D9D9D9;
      cursor: pointer;
      transition: 300ms;
      &:hover, &.active {
        color: #FFFFFF;
        background-color: #1454F2;
        border-color: #1454F2;
      }
      > span {
        @media screen and (max-width: 768px) {
          display: block;
          max-width: 200px;
          white-space: nowrap; /* Запрещаем перенос строк */
          overflow: hidden; /* Обрезаем все, что не помещается в область */
          text-overflow: ellipsis; /* Добавляем многоточие */
        }
      }
    }
  }

  &__not-found {
    position: absolute;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
    word-break: break-word;
    max-width: max-content;
    text-align: center;
    height: auto !important;
    background: transparent;
    color: black;
  }

  > div {
    display: flex;
    align-items: center;

    height: 100%;
    background: transparent;
    border-radius: 6px;
  }
  .video-player {
    height: calc(100% - 63px);
  }
  .video-js {
    background-color: transparent;
    border-radius: 10px;
    width: 100%;
    height: inherit;
    transition: 0.2s;
    //@media screen and (max-width: 600px) {
    //  height: calc(100vh - 147px);
    //}
    //@media screen and (max-width: 410px) {
    //  height: calc(100vh - 158px);
    //}

    .vjs-big-play-button {
      height: 50px;
      width: 50px;
      top: calc(50% - 45px);
      left: calc(50% - 25px);
      padding: 0;
      cursor: pointer;
      opacity: 1;
      border: 0.06666em solid #fff;
      background-color: #1454F2;
      border-radius: 100%;
      transition: all 0.4s;
    }

    .vjs-control-bar {
      border-radius: 2px !important;
      //bottom: 138px;
    }

    .vjs-play-progress {
      background: #1454F2;
    }

    .vjs-modal-dialog {
      border-radius: 4px;
    }

    .vjs-tech {
      position: relative;
      border-radius: 4px;
    }
  }

  .vjs-poster {
    border-radius: 4px;
  }

  iframe {
    width: 100%;
    height: 100%;

    border-radius: 4px;
  }
}
</style>