<template>
  <div class="admin-course__wrapper" :style="{background: course.color}">
    <div class="admin-course__info">
      <div class="admin-course__info-title">
        <div class="admin-course__info-title--edit">Название курса</div>
        <el-input class="admin-course__info-title-input"
                  placeholder="Название курса"
                  v-model="course.name"/>
      </div>
<!--      <div class="admin-course__info-description admin-course__info-description&#45;&#45;edit">-->
<!--        <div class="admin-course__info-title&#45;&#45;edit">Описание курса</div>-->
<!--        <el-input class="admin-course__info-description-input"-->
<!--                  type="textarea"-->
<!--                  min="5"-->
<!--                  autosize-->
<!--                  placeholder="Описание курса"-->
<!--                  v-model="course.description"/>-->
<!--      </div>-->
      <div class="admin-course__header-options">
<!--        <div class="admin-course__price-edit">-->
<!--          <div class="admin-course__info-title&#45;&#45;edit">Старая цена</div>-->
<!--          <div class="admin-course__info-price admin-course__info-price&#45;&#45;edit">-->
<!--            <el-input class="admin-course__price-input"-->
<!--                      placeholder="Цена"-->
<!--                      @input="course.oldPrice = course.oldPrice.replace(/^0+/, '')"-->
<!--                      min="1"-->
<!--                      maxlength="6"-->
<!--                      @blur="clearIfEmptyOldPrice"-->
<!--                      v-number.integer="true"-->
<!--                      v-model="course.oldPrice"/>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="admin-course__price-edit">-->
<!--          <div class="admin-course__info-title&#45;&#45;edit">Новая цена</div>-->
<!--          <div class="admin-course__info-price admin-course__info-price&#45;&#45;edit">-->
<!--            <el-input class="admin-course__price-input"-->
<!--                      placeholder="Цена"-->
<!--                      min="1"-->
<!--                      @input="course.price = course.price.replace(/^0+/, '')"-->
<!--                      maxlength="6"-->
<!--                      @blur="clearIfEmpty"-->
<!--                      v-number.integer="true"-->
<!--                      v-model="course.price"/>-->
<!--          </div>-->
<!--        </div>-->
        <div class="admin-course__color-edit">
          <div class="admin-course__info-title--edit">Цвет курса</div>
          <div class="admin-course__info-color">
            <div class="admin-course__select-item" :style="{background: course.color}"/>
            <div class="admin-course__color-piker">
              <el-color-picker v-model="course.color" popper-class="admin-course__color-piker-modal"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="admin-course__info-logo">
      <div class="admin-course__info-logo-edit" :style="course.picture ? 'background: transparent': ''">
        <course-image-loader mode="logo" v-model="course.picture"/>
      </div>
    </div>
  </div>
</template>

<script>
import CourseImageLoader from "../../CourseImageLoader";

export default {
  name: "CourseHeaderEditor",
  components: {
    CourseImageLoader
  },
  props: {
    course: {},
  },
  methods: {
    clearIfEmpty(e) {
      if (e.target.value === '' || e.target.value === '0')
        this.course.price = 100
    },
    clearIfEmptyOldPrice(e) {
      if (e.target.value === '' || e.target.value === '0')
        this.course.oldPrice = 0
    }
  }
}
</script>

<style lang="scss">
.admin-course {
  &__wrapper {
    display: flex;

    padding: 28px 28px 44px 28px;
    margin-bottom: 32px;
    border-radius: 20px;

  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    width: 100%;
  }

  &__header-options {
    display: flex;
  }

  &__info-color {
    display: flex;
    position: relative;

    width: 144px;
    height: 52px;

    background: #FFFFFF;
    border-radius: 4px;
  }

  &__select-item {
    width: 86px;
    height: 32px;

    margin: auto;
    border-radius: 4px;
  }

  &__color-piker {
    position: absolute;
    left: 15px;
    top: 10px;

    opacity: 0;

    .el-color-picker {
      height: max-content;

      &__trigger {
        width: 86px;
        height: 32px;
      }

      &__panel {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  &__color-piker-modal {
    margin-top: 10px;
    margin-bottom: 15px;

    .el-button--text {
      display: none;
    }
  }

  &__info-title {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #2A2A2A;

    margin-bottom: 14px;

    &--edit {
      margin-bottom: 6px;

      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  &__info-title-input {
    .el-input {
      &__inner {
        height: 60px;

        font-family: Raleway, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;

        color: #2A2A2A;
      }
    }
  }

  &__info-price {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Montserrat, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    height: 52px;
    min-width: 130px;

    border-radius: 38px;
    background: #FFFFFF;
    padding: 0 20px;

    &--edit {
      min-width: 100px;
      width: 140px;
      height: 52px !important;
      border-radius: 5px;
      padding: 0 !important;
    }
  }

  &__price-edit {
    margin-right: 32px;
  }

  &__price-input {
    .el-input {
      &__inner {
        font-family: Montserrat, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        border: none;

        height: 52px;
        color: #000000;
        padding: 0px;
      }
    }
  }

  &__info-logo {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    width: 100%;
    max-height: 300px;
  }

  &__info-logo-img {
    max-width: 360px;
    max-height: 300px;
    min-height: 300px;

    border-radius: 5px;
  }

  &__info-logo-edit {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 360px;

    background: #FFFFFF;
    border-radius: 5px;
  }

  &__info-description {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;

    max-width: 500px;
    word-break: break-word;

    margin-top: 32px;
    margin-bottom: 32px;

    &--edit {
      margin-top: 6px;
      width: 100%;
      max-width: 100%;
    }
  }

  &__info-description-input {
    .el-textarea {
      &__inner {
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;


        color: #121212;

        min-height: 100px !important;

        &::-webkit-scrollbar {
          width: 0;
        }
      }
    }
  }

}
</style>