<template>
  <div class="practical-lesson">
    <div class="text-lesson__content-input">
      <div class="text-lesson__label">Вопрос</div>
      <el-input class="content-lesson-input"
                placeholder="Вопрос"
                type="textarea"
                autosize
                rows="5"
                v-model="lesson.question"/>
    </div>
    <div class="practical-lesson__answers-wrapper">
      <div class="practical-lesson__answers">
        <div class="practical-lesson__answer-input" v-for="(item,index) in lesson.answers" :key="index">
          <div class="practical-lesson__label">Ответ {{ index + 1 }}</div>
          <span v-if="isUniqueAnswer(item)"
                class="practical-lesson__label-warning">(данный ответ уже существувет)</span>
          <div>

            <el-input placeholder="Введите ответ"
                      type="text"
                      v-model="item.text"/>
            <img class="practical-lesson__img-remove"
                 src="@/assets/img/courses/program/icon-remove-red.svg"
                 alt="del"
                 @click="clickDeleteAnswer(index)">
          </div>

        </div>

      </div>
      <div class="practical-lesson__choice">
        <div class="practical-lesson__choice-answers">
          <div v-if="lesson.answers.length" class="practical-lesson__choice-header">Отметьте правильные ответы</div>
          <el-checkbox @change="selectAnswers"
                       v-model="item.isRight"
                       :disabled="item.text.length === 0 || isUniqueAnswer(item)"
                       :label="item.text"
                       v-for="(item,index) in lesson.answers" :key="index"/>
        </div>
      </div>
    </div>

    <div class="practical-lesson__btn-for-lesson">
      <div>

        <div class="practical-lesson__add-answer" @click="clickAddAnswer">
          <img src="@/assets/img/courses/program/button/icon-add.svg" alt="add">
          <div class="practical-lesson__add-title">Добавить ответ</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {validate} from "../../../../../../../../utils/validators";
import {maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
  name: "PracticalLesson",
  mixins: [validationMixin],
  components: {
  },
  props: {
    lesson: Object,
    index: Number,
    maxNumber: Number
  },
  computed: {
    isSelectedAnswer() {
      return this.lesson.answers && this.lesson.answers.filter(answer => answer.isRight === true).length === 0
    },
    isEmptyAnswer() {
      return this.lesson.answers && this.lesson.answers.filter(answer => answer.text === '').length > 0
    },
    isModifiedLesson() {
      return JSON.stringify(this.lesson) === JSON.stringify(this.copyLesson) ||
          !validate(this.$v) || this.isSelectedAnswer || this.isEmptyAnswer
    },
    isValidQueueNumber() {
      return Number(this.copyLesson.queueNumber) !== this.lesson.queueNumber && this.copyLesson.queueNumber !== ''
          && this.copyLesson.queueNumber !== '0' && this.maxNumber >= this.copyLesson.queueNumber && this.lesson.id
    },
  },
  data() {
    return {
      selectedTasks: [],
      idDeletedAnswers: [],
      copyLesson: null
    }
  },
  watch: {
    lesson() {
      this.copyLesson = JSON.parse(JSON.stringify(this.lesson))
    },
    'lesson.queueNumber'(){
      this.copyLesson = JSON.parse(JSON.stringify(this.lesson))
    }
  },
  created() {
    this.copyLesson = JSON.parse(JSON.stringify(this.lesson))

    if (this.lesson.answers) {
      this.lesson.answers.forEach((item) => {
        if (item.isRight)
          this.selectedTasks.push(item.text)
      })

    }
  },
  methods: {
    clickAddAnswer() {
      if (this.lesson.answers && this.lesson.answers.length < 10)
        this.lesson.answers.push({text: '', isRight: false})
      else {
        this.$notify({
          title: 'Предупреждение',
          message: 'Максимальное кол-во ответов 10',
          type: 'warning'
        });
      }
    },
    clickDeleteAnswer(index) {
      if (this.lesson.answers[index].id)
        this.idDeletedAnswers.push(this.lesson.answers[index].id)

      this.lesson.answers.splice(index, 1)
    },
    selectAnswers() {
      this.selectedTasks = []
      this.lesson.answers.forEach(item => {
        if(item.isRight && item.text.length)
          this.selectedTasks.push(item.text)
      })
    },
    isUniqueAnswer(item) {
      if (this.lesson.answers.find(task => task.text === item.text && task.id !== item.id))
        return true
      else
        return false
    },
    clickSave() {
      if (!this.isModifiedLesson) {

        this.copyLesson = JSON.parse(JSON.stringify(this.lesson))

        if (this.lesson.id)
          this.$emit('updateLesson', {lesson: this.lesson, idDeletedAnswers: this.idDeletedAnswers})
        else
          this.$emit('addLesson', {lesson: this.lesson, index: this.index})
      }
    },
    updateQueueNumber() {
      if (this.isValidQueueNumber) {

        this.$emit('updateQueueNumber',
            {
              number: this.lesson.queueNumber,
              newNumber: Number(this.copyLesson.queueNumber),
              id: this.lesson.id
            })
      } else {
        this.copyLesson.queueNumber = this.lesson.queueNumber
      }
    }

  },
  validations: {
    lesson: {
      name: {required, minLength: minLength(2), maxLength: maxLength(164)},
      description: {required, minLength: minLength(2)},
    }
  }
}
</script>

<style lang="scss">

.practical-lesson {
  display: flex;
  flex-direction: column;

  margin-top: 17px;

  .number-lesson {
    .el-input__inner {
      position: absolute;
      width: 35px;
      height: 35px;

      left: 85px;
      top: 7px;
      padding: 0 5px;

      font-family: Montserrat, sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #121212;

    }
  }

  .el-collapse {
    border: none;
  }

  .el-collapse-item__header {
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    padding: 0 15px;


    border-radius: 100px;
    border: 1px solid #DCDFE6;
  }
  .el-collapse-item__content {
    margin-top:10px;
  }
  &__label {
    display: inline-block;

    margin-right: 10px;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 10px;

    &--warning {
      font-size: 12px;
    }
  }

  &__label-warning {
    display: inline-block;
    margin-right: 10px;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 10px;
  }

  &__title-input {
    margin-top: 24px;

    .el-input__inner {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #121212;
      height: 50px;

      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 100px;
    }

  }

  &__content-input {
    margin: 20px 0px;

    .el-textarea__inner {
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #121212;
      min-height: 180px !important;

      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 20px;
    }

  }

  &__answers-wrapper {
    display: flex;
  }

  &__answers {
    width: 100%;
  }

  &__choice {

  }

  &__choice-header {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;

    width: 126px;
    margin-top: -10px;
    margin-bottom: 15px;
  }

  &__choice-answers {
    display: flex;
    flex-direction: column;
    align-items: center;


    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #4EE1A3;
      border-color: #4EE1A3;
    }

    .el-checkbox__inner:hover {
      border-color: #4EE1A3;
    }

    .el-checkbox__input.is-focus {
      border-color: #4EE1A3;
    }

    .el-checkbox__input.is-focus .el-checkbox__inner {
      border-color: #DCDFE6;
    }

    .el-checkbox:last-child {
      margin-bottom: 0px;
    }

    .el-checkbox {
      margin-right: 0px;
      margin-bottom: 74px;

      &__label {
        display: none;

      }

      &__inner {
        width: 24px;
        height: 24px;

        border-radius: 4px;

        &:after {
          width: 5px;
          height: 12px;

          left: 8px;
        }
      }
    }
  }


  &__answer-input {
    position: relative;

    .el-input__inner {
      padding-right: 40px;

      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #121212;

      margin-bottom: 20px;
      height: 50px;

      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 100px;
    }

  }

  &__img-remove {
    position: absolute;
    top: 45px;
    right: 20px;

    cursor: pointer;
  }

  &__add-answer {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 245px;
    height: 50px;

    background: #407BFF;
    border-radius: 120px;

    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      bottom: 50%;
      left: 0;
      width: 100%;
      height: 0;
      background: rgba(255, 255, 255, 0.1);
    }

    &:hover:before {
      transition: 400ms;
      bottom: 0;
      height: 100%;
    }
  }

  &__add-title {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    color: #FFFFFF;
    margin-left: 15px;
  }


  &__btn-for-lesson {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }


  &__save-btn {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 222px;

    margin-top: 10px;

    background: #4EE1A3;
    border-radius: 120px;

    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      bottom: 50%;
      left: 0;
      width: 100%;
      height: 0;
      background: rgba(255, 255, 255, 0.1);
    }

    &:hover:before {
      transition: 400ms;
      bottom: 0;
      height: 100%;
    }

    &--disabled {
      background: #C4C4C4;
      cursor: not-allowed;

    }
  }

  &__save-btn-title {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    color: #FFFFFF;
  }

  &__btn-type-title {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    color: #121212;
    padding: 13px 20px;

    width: 233px;
    height: 50px;

    border: 2px solid #4EE1A3;
    box-sizing: border-box;
    border-radius: 120px;

    cursor: default;
  }

  &__btn-type-label {
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 10px;
  }

}

</style>