<template>
  <div>
    <div class="admin-courses__header">
      <div class="admin-courses__title">Направления</div>
      <add-button @click.native="openAddCategoryModal">Добавить направление</add-button>
    </div>

    <transition-group name="list" class="admin-courses__buttons">
      <category-item class="admin-courses__button"
                     v-for="item of categories"
                     :key="item.id"
                     :category="item"
                     @update-category="updateCategory"
                     @delete-category="deleteCategoryHandler"
                     @click="selectedCategory"/>
    </transition-group>

    <add-category-modal ref="addCategoryModal"
                        :loading="loadingCategory"
                        @add-category="addCategoryHandler"/>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

import AddButton from "../../../components/AddButton";
import CategoryItem from "./CategoryItem";
import AddCategoryModal from "./modals/AddCategoryModal";

import {courseService} from "../../course.service";

export default {
  name: "Categories",
  components: {
    AddButton,
    CategoryItem,
    AddCategoryModal
  },
  computed: {
    ...mapGetters(['categories','courses'])
  },
  data() {
    return {
      loadingCategory: false,
    }
  },
  methods: {
    ...mapMutations(['setCategories','selectCourseId', 'addCategory', 'deleteCategory', 'selectCategory']),

    selectedCategory(category) {
      this.selectCategory(category)

      const filteredCategories = this.courses.filter(course => course.categories.includes(category.id))
      if(filteredCategories.length)
        this.selectCourseId(filteredCategories[0].id)

    },

    addCategoryHandler: async function (categoryName) {
      this.loadingCategory = true

      await courseService.addCategory(categoryName).then(category =>{
        this.selectCategory(category)
        this.addCategory(category)
      })

      this.loadingCategory = false
      this.$refs.addCategoryModal.closeModal()
    },

    deleteCategoryHandler: function (categoryId) {
      courseService.deleteCategory(categoryId)
      this.deleteCategory(categoryId)
    },

    updateCategory: function (category) {
      courseService.updateCategory(category).then(data => {
        this.categories.find(item => item.id === data.id).name = category.name
      })
    },

    openAddCategoryModal: function () {
      this.$refs.addCategoryModal.openModal(this.categories)
    }
  }
}
</script>

<style scoped lang="scss">
.admin-courses {
  &__header {
    display: flex;
    flex-direction: column;

    margin-bottom: 30px;
  }

  &__title {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #2A2A2A;

    margin-bottom: 14px;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;

    margin-top: 32px;
  }

  &__button {
    margin-right: 15px;
    margin-bottom: 24px;

    &:active {
    }
  }
}
</style>