<template>
  <div class="btn-for-lessons">
<!--    <div class="btn-for-lessons__label">Добавить вид урока</div>-->
    <div class="btn-for-lessons__items">
      <add-lesson-btn @click.native="$emit('addLesson')" label="Добавить тему"/>
<!--      <add-lesson-btn @click.native="$emit('addLesson','VideoLesson')"  label="Видеоурок"/>-->
<!--      <add-lesson-btn @click.native="$emit('addLesson','PracticalLesson')"  label="Практическое занятие"/>-->
    </div>
  </div>
</template>

<script>
import AddLessonBtn from "./AddLessonBtn";

export default {
  name: "BtnForLessons",
  components:{AddLessonBtn}

}
</script>

<style lang="scss" scoped>
.btn-for-lessons{
  margin-top:50px;

  &__label{
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 10px;
  }

  &__items{
    display: flex;
    justify-content: space-between;
  }
}
</style>