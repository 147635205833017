<template>
  <div>
    <div class="admin-course__title">Благодаря курсу вы</div>

    <div class="admin-course__options">
      <div class="admin-course__option" v-for="(option, index) in learningTopics" :key="index">

        <div class="admin-course__option-number">0{{index+1}}</div>
          <el-input class="admin-course__option-title-input"
                    placeholder="Вы научитесь"
                    type="textarea"
                    rows="2"
                    v-model="option.author"/>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseStudyEditor",
  props: {
    learningTopics: {
      type: Array
    },
  },
}
</script>

<style lang="scss">
.admin-course {
  &__options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px 30px;

    margin-bottom: 140px;
  }

  &__option {
    padding: 30px;
    background: #FFF6F4;
    border-radius: 26px;
  }

  &__option-number {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;

    color: #222222;
    margin-bottom: 14px;
  }

  &__option-title {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    word-break: break-all;
  }

  &__option-title-input {
    .el-textarea {
      &__inner {
        font-family: Raleway, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-align: left;

        color: #000000;
        min-height: 60px !important;


        &::-webkit-scrollbar {
          width: 0;
        }
      }
    }
  }

  &__option-text {
    font-family: Raleway, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    word-break: break-all;

    max-width: 487px;
    margin-top: 10px;
  }

  &__option-text-input {
    .el-textarea {
      &__inner {
        font-family: Raleway, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        padding: 14px;
        overflow: hidden;
        color: #000000;
      }
    }
  }
}

</style>
