<template>
  <button class="add-button" type="button">
    <img src="../../../assets/img/button/add.svg" alt="add">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "AddButton",
}
</script>

<style lang="scss" scoped>
  .add-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 36px;
    padding-right: 32px;

    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    white-space: nowrap;


    width: max-content;
    height: 54px;

    border: 2px solid #1454F2;
    box-sizing: border-box;
    border-radius: 120px;

    background: #1454F2;

    cursor: pointer;

    transition: all .2s ease-in;

    text-decoration: none;
    img{
      margin-right: 14px;
    }

  }
</style>
