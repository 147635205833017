<template>
  <el-dialog
      :visible="true"
      top="15vh"
      class="video-intro-modal"
      close-on-press-escape
      :before-close="()=>{$emit('closeModal')}">

    <lesson-video-intro :path="path"/>

  </el-dialog>
</template>

<script>

import LessonVideoIntro from "./CourseProgramInfo/components/LessonVideoIntro";
export default {
  name: "CourseVideoIntroModal",
  components:{
    LessonVideoIntro
  },
  props: {
    path: {type: String, default: null}
  }
}
</script>

<style lang="scss">
.video-intro-modal {

  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);

  .lesson-video-intro__video {
    margin-bottom: 0;
  }
  .el-dialog {
    width: 100%;
    max-width: 700px;
    background: transparent;
    box-shadow: none;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0 0;
  }
  &::-webkit-scrollbar {
    opacity: 0;
  }

}
</style>