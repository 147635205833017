import http from '@/app/admin/config/admin-axios.config'
import cancelableWrapper from "../../../utils/cancelableWrapper";

class AdminRepository {
    _adminsPagePromise = null

    async getAdminInfo() {
        const response = await http.get('/me')
        return Promise.resolve(response.data)
    }

    async getAdminsPage({page, limit}) {
        if (this._adminsPagePromise) this._adminsPagePromise.cancel()

        this._adminsPagePromise = cancelableWrapper(http.get(`/admins`, {params: {limit, page, sort_by_id: 'ASC'}}))

        const response = await this._adminsPagePromise

        return Promise.resolve(response.data)
    }

    async addAdmin(newAdmin) {
        const response = await http.put('/admins', newAdmin)
        return Promise.resolve(response.data)
    }

    async updateAdmin(admin) {
        const adminId = admin.id
        delete admin.email
        delete admin.id

        const response = await http.patch(`/admins/${adminId}`, admin)
        return Promise.resolve(response.data)
    }

    async deleteAdmin(adminId) {
        const response = await http.delete(`/admins/${adminId}`)
        return Promise.resolve(response.data)
    }

}

export const adminRepository = new AdminRepository()
