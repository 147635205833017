<template>
  <div class="app-editor"
       :class="{ 'app-editor--invalid': validator && validator.$error }">
    <div :id="`app-editor-${this.customKey}`" class="app-editor-content"></div>
  </div>
</template>

<script>
export default {
  name: "AppEditor",
  props: {
    value: {type: String},
    validator: {type: Object, default: null}
  },
  data() {
    return {
      editor: null
    }
  },
  computed: {
    customKey() {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < 8) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    }
  },
  mounted() {
    // const interval = setInterval(async () => {
    // setTimeout(async () => {
    this.createEditor().then(() => {
      this.subscribeOnChanges()
    })
    // }, 600)

    // }, 50)
  },
  methods: {
    createEditor() {
      /* eslint no-undef: 0 */
      return ClassicEditor.create(
          document.querySelector(`#app-editor-${this.customKey}`),
          {
            initialData: this.value
          }
      ).then((resp) => {
        this.editor = resp
      })
    },

    subscribeOnChanges() {
      this.editor.model.document.on('change:data', () => {
        this.$emit('input', this.editor.getData())
      })
    },
  }
}
</script>

<style lang="scss">
.app-editor {

  &--invalid {
    .ck-editor__editable {
      border: 2px solid red !important;
    }
  }

  .ck-content {
    margin-bottom: 10px;
    min-height: 300px;
  }

  .table {
    width: 100%;
  }

  table {
    border-collapse: collapse !important;
    border-radius: 6px;

    tr {
      height: 45px;
    }

    td {
      background-color: #efefef;
      border: none !important;
      text-align: center;
    }
  }

  .app-editor-content ~ .ck {
    margin-bottom: 10px;
  }

  .ck-source-editing-area {
    textarea {
      left: 0;
    }
  }
}
</style>